let count = 0;

export default function dataTreeConverter(tree) {
  let converted = [];
  count = 0;
  if (tree && tree.kategorie_gruppe) {
    const data = tree.kategorie_gruppe;
    for (var i = 0; i < data.length; i++) {
      converted.push.apply(converted, splitKategorieGruppe(data[i]));
    }
  }
  return { data: converted, count: count };
}

const splitKategorieGruppe = (data) => {
  if (data) {
    const key = data.kategorie_gruppe_kennung;
    const children = [];
    if (data.kategorie_titel) {
      for (var i = 0; i < data.kategorie_titel.length; i++) {
        children.push.apply(
          children,
          splitKategorieTitel(
            key,
            data.kategorie_titel[i],
            data.kategorie_gruppe_kennung,
            data.kategorie_gruppe_name,
            data.kategorie_gruppe_beschreibung,
            data.kategorie_kurztext
          )
        );
      }
    }
    return data.kategorie_gruppe_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.kategorie_gruppe_kennung,
              name: data.kategorie_gruppe_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitKategorieTitel = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext
) => {
  if (data) {
    const key = parent_key + "-" + data.kategorie_titel_kennung;
    const children = [];
    const mergedKurztext = data.kategorie_kurztext
      ? data.kategorie_kurztext
      : kurztext;
    if (data.kategorie_untertitel) {
      for (var i = 0; i < data.kategorie_untertitel.length; i++) {
        children.push.apply(
          children,
          splitKategorieUnterTitel(
            key,
            data.kategorie_untertitel[i],
            gruppe_kennung,
            gruppe_name,
            gruppe_beschreibung,
            mergedKurztext,
            data.kategorie_titel_kennung,
            data.kategorie_titel_name,
            data.kategorie_titel_beschreibung
          )
        );
      }
    }
    return data.kategorie_titel_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.kategorie_titel_kennung,
              name: data.kategorie_titel_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitKategorieUnterTitel = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext,
  titel_kennung,
  titel_name,
  titel_beschreibung
) => {
  if (data) {
    const key = parent_key + "-" + data.kategorie_untertitel_kennung;
    const children = [];
    const mergedKurztext = data.kategorie_kurztext
      ? data.kategorie_kurztext
      : kurztext;
    if (data.kostengruppe) {
      for (var i = 0; i < data.kostengruppe.length; i++) {
        children.push.apply(
          children,
          splitKostengruppe(
            key,
            data.kostengruppe[i],
            gruppe_kennung,
            gruppe_name,
            gruppe_beschreibung,
            mergedKurztext,
            titel_kennung,
            titel_name,
            titel_beschreibung,
            data.kategorie_untertitel_kennung,
            data.kategorie_untertitel_name,
            data.kategorie_untertitel_beschreibung
          )
        );
      }
    }
    return data.kategorie_untertitel_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.kategorie_untertitel_kennung,
              name: data.kategorie_untertitel_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitKostengruppe = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext,
  titel_kennung,
  titel_name,
  titel_beschreibung,
  untertitel_kennung,
  untertitel_name,
  untertitel_beschreibung
) => {
  if (data) {
    const key = parent_key + "-" + data.kostengruppe_kennung;
    const children = [];
    if (data.anlagenklasse) {
      for (var i = 0; i < data.anlagenklasse.length; i++) {
        children.push.apply(
          children,
          splitAnlagenklasse(
            key,
            data.anlagenklasse[i],
            gruppe_kennung,
            gruppe_name,
            gruppe_beschreibung,
            kurztext,
            titel_kennung,
            titel_name,
            titel_beschreibung,
            untertitel_kennung,
            untertitel_name,
            untertitel_beschreibung,
            data.kostengruppe_kennung,
            data.kostengruppe_name
          )
        );
      }
    }
    return data.kostengruppe_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.kostengruppe_kennung,
              name: data.kostengruppe_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitAnlagenklasse = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext,
  titel_kennung,
  titel_name,
  titel_beschreibung,
  untertitel_kennung,
  untertitel_name,
  untertitel_beschreibung,
  kostengruppe_kennung,
  kostengruppe_name
) => {
  if (data) {
    const key = parent_key + "-" + data.klasse_kennung;
    const children = [];
    if (data.anlage) {
      for (var i = 0; i < data.anlage.length; i++) {
        children.push.apply(
          children,
          splitAnlage(
            key,
            data.anlage[i],
            gruppe_kennung,
            gruppe_name,
            gruppe_beschreibung,
            kurztext,
            titel_kennung,
            titel_name,
            titel_beschreibung,
            untertitel_kennung,
            untertitel_name,
            untertitel_beschreibung,
            kostengruppe_kennung,
            kostengruppe_name,
            data.klasse_kennung,
            data.klasse_name
          )
        );
      }
    }
    return data.klasse_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.klasse_kennung,
              name: data.klasse_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitAnlage = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext,
  titel_kennung,
  titel_name,
  titel_beschreibung,
  untertitel_kennung,
  untertitel_name,
  untertitel_beschreibung,
  kostengruppe_kennung,
  kostengruppe_name,
  klasse_kennung,
  klasse_name
) => {
  if (data) {
    const key = parent_key + "-" + data.anlage_kennung;
    const children = [];
    if (data.art) {
      for (var i = 0; i < data.art.length; i++) {
        children.push.apply(
          children,
          splitArt(
            key,
            data.art[i],
            gruppe_kennung,
            gruppe_name,
            gruppe_beschreibung,
            kurztext,
            titel_kennung,
            titel_name,
            titel_beschreibung,
            untertitel_kennung,
            untertitel_name,
            untertitel_beschreibung,
            kostengruppe_kennung,
            kostengruppe_name,
            klasse_kennung,
            klasse_name,
            data.anlage_kennung,
            data.anlage_name
          )
        );
      }
    }
    return data.anlage_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.anlage_kennung,
              name: data.anlage_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitArt = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext,
  titel_kennung,
  titel_name,
  titel_beschreibung,
  untertitel_kennung,
  untertitel_name,
  untertitel_beschreibung,
  kostengruppe_kennung,
  kostengruppe_name,
  klasse_kennung,
  klasse_name,
  anlage_kennung,
  anlage_name
) => {
  if (data) {
    const key = parent_key + "-" + data.art_kennung;
    const children = [];
    if (data.bandbreite) {
      for (var i = 0; i < data.bandbreite.length; i++) {
        children.push(
          splitBandbreite(
            key,
            data.bandbreite[i],
            gruppe_kennung,
            gruppe_name,
            gruppe_beschreibung,
            kurztext,
            titel_kennung,
            titel_name,
            titel_beschreibung,
            untertitel_kennung,
            untertitel_name,
            untertitel_beschreibung,
            kostengruppe_kennung,
            kostengruppe_name,
            klasse_kennung,
            klasse_name,
            anlage_kennung,
            anlage_name,
            data.art_kennung,
            data.art_name
          )
        );
      }
    }
    return data.art_kennung
      ? [
          {
            key: key,
            data: {
              kennung: data.art_kennung,
              name: data.art_name,
              unit_nummer: null,
              gefma_code: null,
              ep_code: null,
              einheit: null,
              vortext: null,
              nachtext: null,
            },
            children: children,
          },
        ]
      : children;
  }
};

const splitBandbreite = (
  parent_key,
  data,
  gruppe_kennung,
  gruppe_name,
  gruppe_beschreibung,
  kurztext,
  titel_kennung,
  titel_name,
  titel_beschreibung,
  untertitel_kennung,
  untertitel_name,
  untertitel_beschreibung,
  kostengruppe_kennung,
  kostengruppe_name,
  klasse_kennung,
  klasse_name,
  anlage_kennung,
  anlage_name,
  art_kennung,
  art_name
) => {
  count++;
  if (data) {
    const key = parent_key + "-" + data.bandbreite_kennung;
    return {
      key: key,
      data: {
        kategorie_gruppe_beschreibung: gruppe_beschreibung,
        kategorie_gruppe_kennung: gruppe_kennung,
        kategorie_gruppe_name: gruppe_name,
        kategorie_kurztext: kurztext,
        kategorie_titel_beschreibung: titel_beschreibung,
        kategorie_titel_kennung: titel_kennung,
        kategorie_titel_name: titel_name,
        kategorie_untertitel_beschreibung: untertitel_beschreibung,
        kategorie_untertitel_kennung: untertitel_kennung,
        kategorie_untertitel_name: untertitel_name,
        kostengruppe_kennung: kostengruppe_kennung,
        kostengruppe_name: kostengruppe_name,
        klasse_kennung: klasse_kennung,
        klasse_name: klasse_name,
        anlage_kennung: anlage_kennung,
        anlage_name: anlage_name,
        art_kennung: art_kennung,
        art_name: art_name,
        bandbreite_kennung: data.bandbreite_kennung,
        bandbreite_name: data.bandbreite_name,
        kalkulationstyp_kennung: data.kalkulationstyp_kennung,
        intervall_kennung: data.intervall_kennung,
        stundensatz_kennung: data.stundensatz_kennung,
        projektfaktor_kennung: data.projektfaktor_kennung,
        leistungsbereichfaktor_kennung: data.leistungsbereichfaktor_kennung,
        //used for table tree
        kennung: data.bandbreite_kennung,
        name: data.bandbreite_name,
        unit_nummer: data.unit_nummer,
        gefma_code: data.gefma_code,
        ep_code: data.ep_code,
        einheit: data.einheit,
        vortext: data.vortext,
        nachtext: data.nachtext,
      },
    };
  }
};
