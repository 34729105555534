import React, { useState } from "react";
import { connect } from "react-redux";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import EpDetail from "../components/EpDetail";
import { browseAllEp } from "../../redux/ep_data/epDataActions";
import dataTreeConverter from "../../helper/dataTreeConverter";
import { createGlobalNotification } from "../../redux/notification/notificationActions";
import { hideSettingsUI } from "../../redux/app/appActions";
import "./EpBrowse.css";
import { Dialog } from "primereact/dialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { OPTION_COPY_EP, OPTION_COPY_EXCEL } from "../../helper/constants";

function EpBrowse(props) {
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  function nameTemplate(node, column) {
    const kennung =
      node && node.data && node.data.kennung ? node.data.kennung : "";
    const name = node && node.data && node.data.name ? node.data.name : "";
    const gefma =
      node && node.data && node.data.gefma_code ? node.data.gefma_code : null;
    return [
      <>
        <span className="table-data-number">[{kennung}] </span>
        <span className="table-data-content">{name}</span>
        {gefma ? (
          <Button
            icon="pi pi-info"
            className="p-button-rounded p-button-outlined"
            style={{ marginLeft: "10px" }}
            onClick={() => showEpDetailInfo(node)}
          />
        ) : null}
      </>,
    ];
  }

  function showEpDetailInfo(node) {
    setSelectedNode(node);
    setDialogOpen(!dialogOpen);
  }

  function epCodeTemplate(node, column) {
    const showCode =
      node && node.data && node.data.ep_code
        ? node.data.ep_code.replace("-", " - ")
        : "";
    return [
      <div>
        <span className="table-data-content">{showCode}</span>
      </div>,
    ];
  }

  function gefmaCodeTemplate(node, column) {
    const value =
      node && node.data && node.data.gefma_code ? node.data.gefma_code : "";
    return [
      <div>
        <span className="table-data-content">{value}</span>
      </div>,
    ];
  }

  function vortextTemplate(node, column) {
    const value =
      node && node.data && node.data.vortext ? node.data.vortext : "";
    return [
      <div>
        <span className="table-data-content">{value}</span>
      </div>,
    ];
  }

  function nachtextTemplate(node, column) {
    const value =
      node && node.data && node.data.nachtext ? node.data.nachtext : "";
    return [
      <div>
        <span className="table-data-content">{value}</span>
      </div>,
    ];
  }

  function einheitTemplate(node, column) {
    const value =
      node && node.data && node.data.einheit ? node.data.einheit : "";
    return [
      <div>
        <span className="table-data-content">{value}</span>
      </div>,
    ];
  }

  const formatDate = (value) => {
    if (value) {
      return new Intl.DateTimeFormat("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(value));
    } else {
      return "invalid";
    }
  };

  function actionCopyTemplate(data, index) {
    if (data.data.ep_code) {
      const entry = data.data;
      if (props.copyOption === OPTION_COPY_EP) {
        return (
          <CopyToClipboard
            text={entry.ep_code}
            onCopy={() => {
              props.notifyGlobal("EP-Code kopiert", entry.ep_code, 1000);
              props.hideSettings();
            }}
          >
            <Button type="button" className="in_table_btn">
              <i className="pi pi-copy table_btn_icon" />
            </Button>
          </CopyToClipboard>
        );
      } else if (props.copyOption === OPTION_COPY_EXCEL) {
        const arr = [
          [
            "",
            entry.kategorie_gruppe_kennung
              ? '"' + entry.kategorie_gruppe_kennung + '"'
              : "",
            entry.kategorie_gruppe_kennung
              ? '"' + entry.kategorie_gruppe_kennung + '"'
              : "",
            entry.kategorie_titel_kennung
              ? '"' + entry.kategorie_titel_kennung + '"'
              : "",
            entry.kategorie_untertitel_kennung
              ? '"' + entry.kategorie_untertitel_kennung + '"'
              : "",
            entry.titel_nummer ? '"' + entry.titel_nummer + '"' : "",
            entry.titel_nummer ? '"' + entry.titel_nummer + '."' : "",
            "",
            entry.kategorie_gruppe_name
              ? '"' + entry.kategorie_gruppe_name + '"'
              : "",
            entry.kategorie_gruppe_beschreibung
              ? '"' + entry.kategorie_gruppe_beschreibung + '"'
              : "",
            entry.kategorie_titel_name
              ? '"' + entry.kategorie_titel_name + '"'
              : "",
            entry.kategorie_titel_beschreibung
              ? '"' + entry.kategorie_titel_beschreibung + '"'
              : "",
            entry.kategorie_untertitel_name
              ? '"' + entry.kategorie_untertitel_name + '"'
              : "",
            entry.kategorie_untertitel_beschreibung
              ? '"' + entry.kategorie_untertitel_beschreibung + '"'
              : "",
            entry.kategorie_kurztext
              ? '"' + entry.kategorie_kurztext + '"'
              : "",
            entry.gefma_code ? '"' + entry.gefma_code + '"' : "",
            entry.kostengruppe_kennung
              ? '"' + entry.kostengruppe_kennung + '"'
              : "",
            entry.kostengruppe_name ? '"' + entry.kostengruppe_name + '"' : "",
            entry.klasse_name ? '"' + entry.klasse_name + '"' : "",
            entry.anlage_name ? '"' + entry.anlage_name + '"' : "",
            entry.art_name ? '"' + entry.art_name + '"' : "",
            entry.bandbreite_name ? '"' + entry.bandbreite_name + '"' : "",
            entry.einheit ? '"' + entry.einheit + '"' : "",
            entry.unit_nummer ? '"' + entry.unit_nummer + '"' : "",
            entry.ep_code ? '"' + entry.ep_code + '"' : "",
            entry.kalkulationstyp_kennung
              ? '"' + entry.kalkulationstyp_kennung + '"'
              : "",
            "",
            entry.vorext ? '"' + entry.vorext + '"' : "",
            entry.nachtext ? '"' + entry.nachtext + '"' : "",
            "",
            "",
            "0",
            "0",
            "0",
          ],
        ];
        const excelData = arr.map((lines) => lines.join("\t")).join("\n");
        return (
          <CopyToClipboard
            options={{ format: "text/plain" }}
            text={excelData}
            onCopy={() => {
              props.notifyGlobal("EP kopiert", "Default-Excel-Template", 1000);
              props.hideSettings();
            }}
          >
            <Button type="button" className="in_table_btn">
              <i className="pi pi-copy table_btn_icon" />
            </Button>
          </CopyToClipboard>
        );
      }
    }
    return;
  }

  const injectDataTable = () => {
    if (data === null) {
      const payload = dataTreeConverter(props.epBrowseTree);
      if (payload) {
        setData(payload.data);
        setCount(payload.count);
      }
    }
    const date = props.data_valid_timestamp
      ? "- [Stand: " + formatDate(props.data_valid_timestamp) + "]"
      : null;
    const header = count + " Einträge vorhanden " + (date ? date : "");
    return (
      <>
        <TreeTable value={data} header={header} columnResizeMode="expand">
          <Column
            body={nameTemplate}
            header="Name"
            expander
            style={{ width: "50%" }}
          />
          {props.displayOptions.showGefmaCode ? (
            <Column body={gefmaCodeTemplate} header="GEFMA-Code" />
          ) : null}

          {props.displayOptions.showEpCode ? (
            <Column body={epCodeTemplate} header="EP-Code" />
          ) : null}

          {props.displayOptions.showEinheit ? (
            <Column body={einheitTemplate} header="Einheit" />
          ) : null}

          {props.displayOptions.showVortext ? (
            <Column body={vortextTemplate} header="Vortext" />
          ) : null}

          {props.displayOptions.showNachtext ? (
            <Column body={nachtextTemplate} header="Nachtext" />
          ) : null}
          <Column body={actionCopyTemplate} style={{ width: "65px" }} />
        </TreeTable>
        <Dialog
          id="ep-detail-dialog"
          header="Detailansicht"
          visible={dialogOpen}
          style={{ width: "70vw" }}
          dismissableMask={true}
          onHide={() => setDialogOpen(false)}
        >
          <EpDetail data={selectedNode ? selectedNode.data : null} />
        </Dialog>
      </>
    );
  };

  const loadDataFromServer = () => {
    props.browseAllEp(props.searchOptions.searchDate);
  };

  return (
    <div className="content-container card">
      {props.epBrowseTree
        ? injectDataTable()
        : props.browseRequestPending
        ? null
        : loadDataFromServer()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    displayOptions: state.app.displayOptions,
    copyOption: state.app.copyOption,
    epBrowseTree: state.data.epBrowseTree,
    browseRequestPending: state.data.epBrowseRequestPending,
    searchOptions: state.app.searchOptions,
    data_valid_timestamp: state.data.epBrowseTreeTimestamp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    browseAllEp: (timestamp) => dispatch(browseAllEp(timestamp)),
    notifyGlobal: (summary, detail, life) =>
      dispatch(createGlobalNotification(summary, detail, life)),
    hideSettings: () => dispatch(hideSettingsUI()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EpBrowse);
