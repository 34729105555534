import React from "react";

export default function Privacy(props) {
  return (
    <div className="content-container card">
      <h1>
        Datenschutzerklärung für die "EP-Search" der{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          SERTHORO GmbH
        </a>
      </h1>
      <p>Stand: 29.10.2020</p>
      <p>
        Wir begrüßen Sie bei Ihrem Besuch auf unserer Webseite und freuen uns
        über Ihr Interesse an unserem Unternehmen. <br />
        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
        verarbeiten Ihre Daten in Übereinstimmung mit den anwendbaren
        Rechtsvorschriften zum Schutz personenbezogener Daten, insbesondere der
        EU Datenschutz-Grundverordnung (EU-DS-GVO) und den für uns geltenden
        landesspezifischen Ausführungsgesetzen. Mithilfe dieser
        Datenschutzerklärung informieren wir Sie sowohl umfassend über die
        Verarbeitung Ihrer personenbezogenen Daten durch die{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        und die betriebene Plattform{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          "Beestate.io"
        </a>{" "}
        als auch über die Ihnen zustehenden Rechte. Personenbezogene Daten sind
        dabei diejenigen Informationen, die eine Identifizierung einer
        natürlichen Person möglich machen. Dazu gehören insbesondere Name,
        Geburtsdatum, Adresse, Telefonnummer, E-Mail-Adresse aber auch Ihre
        IP-Adresse. Anonyme Daten liegen dann vor, wenn keinerlei Personenbezug
        zum Nutzer hergestellt werden kann.
      </p>
      <h2>Verantwortliche Stelle und Datenschutzbeauftragter </h2>
      <h5>Adresse:</h5>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        <br />
        Herzog-Max-Strasse 44 <br />
        96047 Bamberg
      </p>
      <p>
        <h5>Kontaktinformation:</h5>
        Homepage:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.beestate.io"
        >
          www.beestate.io
        </a>
        <br />
        Telefon: +49 951 91768330 <br />
        E-Mail: <a href="mailto:info@beestate.io">info@beestate.io</a>
      </p>
      <h5>Kontakt des Datenschutzbeauftragten:</h5>
      <p>
        E-Mail:{" "}
        <a href="mailto:datenschutz@serthoro.de">datenschutz@serthoro.de</a>
      </p>
      <h2>Ihre Rechte als betroffene Person</h2>
      <p>
        Zunächst möchten wir Sie an dieser Stelle über Ihre Rechte als
        betroffene Person informieren. Diese Rechte sind in den Art. 15 - 22
        EU-DS-GVO normiert. Dies umfasst:
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dsgvo-gesetz.de/art-15-dsgvo/"
            >
              Das Recht auf Auskunft (Art. 15 EU-DS-GVO)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dsgvo-gesetz.de/art-17-dsgvo/"
            >
              Das Recht auf Löschung (Art. 17 EU-DS-GVO)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dsgvo-gesetz.de/art-16-dsgvo/"
            >
              Das Recht auf Berichtigung (Art. 16 EU-DS-GVO)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dsgvo-gesetz.de/art-20-dsgvo/"
            >
              Das Recht auf Datenübertragbarkeit (Art. 20 EU-DSGVO)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dsgvo-gesetz.de/art-18-dsgvo/"
            >
              Das Recht auf Einschränkung der Datenverarbeitung (Art. 18
              EU-DS-GVO)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dsgvo-gesetz.de/art-21-dsgvo/"
            >
              Das Widerspruchsrecht gegen die Datenverarbeitung (Art. 21
              EU-DS-GVO)
            </a>
          </li>
        </ul>
        Um diese Rechte geltend zu machen, wenden Sie sich bitte an:{" "}
        <a href="mailto:datenschutz@serthoro.de">datenschutz@serthoro.de</a>.
        Gleiches gilt, wenn Sie Fragen zur Datenverarbeitung in unserem
        Unternehmen haben. Weiterhin steht Ihnen ein Beschwerderecht an eine
        Datenschutzaufsichtsbehörde zu.
      </p>
      <h2>Widerspruchsrechte</h2>
      <p>
        Bitte beachten Sie im Zusammenhang mit Widerspruchsrechten folgendes:
        <br />
        Wenn wir Ihre personenbezogenen Daten zum Zwecke der Direktwerbung
        verarbeiten, haben Sie das Recht, dieser Datenverarbeitung jederzeit
        ohne Angaben von Gründen zu widersprechen. Dies gilt auch für ein
        Profiling, soweit es mit der Direktwerbung in Verbindung steht. Wenn Sie
        der Verarbeitung für Zwecke der Direktwerbung widersprechen, so werden
        wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke
        verarbeiten. Der Widerspruch ist kostenlos und kann formfrei erfolgen,
        möglichst an:{" "}
        <a href="mailto:datenschutz@serthoro.de">datenschutz@serthoro.de</a>.
        <br />
        Für den Fall, dass wir Ihre Daten zur Wahrung berechtigter Interessen
        verarbeiten, können Sie dieser Verarbeitung aus Gründen, die sich aus
        Ihrer besonderen Situation ergeben, jederzeit widersprechen; dies gilt
        auch für ein auf diese Bestimmungen gestütztes Profiling. Wir
        verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn,
        wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder
        die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen.
      </p>
      <h2>Zwecke und Rechtsgrundlagen der Datenverarbeitung</h2>
      <p>
        Bei der Verarbeitung Ihrer personenbezogenen Daten werden die
        Bestimmungen der EU-DS-GVO und alle sonstigen geltenden
        datenschutzrechtlichen Bestimmungen eingehalten. Rechtsgrundlagen für
        die Datenverarbeitung ergeben sich insbesondere aus Art. 6 EU-DS-GVO.
        Wir verwenden Ihre Daten zur Geschäftsanbahnung, zur Erfüllung
        vertraglicher und gesetzlicher Pflichten, zur Durchführung des
        Vertragsverhältnisses, zum Anbieten von Produkten und Dienstleistungen
        sowie zur Stärkung der Kundenbeziehung, was auch Analysen zu
        Marketingzwecken und Direktwerbung beinhalten kann. Ihre Einwilligung
        stellt ebenfalls eine datenschutzrechtliche Erlaubnisvorschrift dar.
        Hierbei klären wir Sie über die Zwecke der Datenverarbeitung und über
        Ihr Widerrufsrecht auf. Sollte sich die Einwilligung auch auf die
        Verarbeitung besonderer Kategorien von personenbezogenen Daten
        be-ziehen, werden wir Sie in der Einwilligung ausdrücklich darauf
        hinweisen. Eine Verarbeitung besonderer Kategorien personenbezogener
        Daten i.S.v. Art. 9 Absatz 1 EU-DS-GVO erfolgt nur dann, wenn dies
        aufgrund rechtlicher Vorschriften erforderlich ist und kein Grund zu der
        Annahme besteht, dass Ihr schutzwürdiges Interesse an dem Ausschluss der
        Verarbeitung überwiegt.
      </p>
      <h2>Weitergabe an Dritte</h2>
      <p>
        Wir werden Ihre Daten nur im Rahmen der gesetzlichen Bestimmungen oder
        bei entsprechender Einwilligung an Dritte weitergeben. Ansonsten erfolgt
        eine Weitergabe an Dritte nicht, es sei denn wir sind hierzu aufgrund
        zwingender Rechtsvorschriften dazu verpflichtet (Weitergabe an externe
        Stellen, wie z.B. Aufsichtsbehörden oder Strafverfolgungsbehörden).
      </p>
      <h2>Empfänger der Daten / Kategorien von Empfängern</h2>
      <p>
        Innerhalb unseres Unternehmens stellen wir sicher, dass nur diejenigen
        Personen Ihre Daten erhalten, die diese zur Erfüllung der vertraglichen
        und gesetzlichen Pflichten benötigen. In vielen Fällen unterstützen
        Dienstleister unsere Fachabteilungen bei der Erfüllung ihrer Aufgaben.
        Mit allen Dienstleistern wurde das notwendige datenschutzrechtliche
        Vertragswerk abgeschlossen. Mit den Dienstleistern wird hier bei
        Beauftragung eine spezifische Datenschutzvereinbarung vereinbart.
      </p>
      <h2>Drittlandübermittlung / Drittlandsübermittlungsabsicht</h2>
      <p>
        Eine Datenübermittlung in Drittstaaten (außerhalb der Europäischen Union
        bzw. des Europäischen Wirtschaftsraums) findet nur statt, soweit dies
        zur Durchführung des Schuldverhältnisses erforderlich, gesetzlich
        vorgeschrieben ist oder Sie uns dazu Ihre Einwilligung erteilt haben.
        Die Einhaltung des Datenschutzniveaus wird dabei gewährleistet durch
        unternehmerische Datenschutzvorschriften und den Abschluss des
        datenschutzrechtlich erforderlichen Vertragswerks.
      </p>
      <h2>Speicherdauer der Daten</h2>
      <p>
        Wir speichern Ihre Daten solange diese für den jeweiligen
        Verarbeitungszweck benötigt werden. Bitte beachten Sie, dass zahlreiche
        Aufbewahrungsfristen bedingen, dass Daten weiterhin gespeichert werden
        (müssen). Dies betrifft insbesondere handelsrechtliche oder
        steuerrechtliche Aufbewahrungspflichten (z.B. Handelsgesetzbuch,
        Abgabenordnung, etc.). Sofern keine weitergehenden
        Aufbewahrungspflichten bestehen, werden die Daten nach Zweckerreichung
        routinemäßig gelöscht. Hinzu kommt, dass wir Daten aufbewahren können,
        wenn Sie uns hierfür Ihre Erlaubnis erteilt haben oder wenn es zu
        rechtlichen Auseinandersetzungen kommt und wir Beweismittel im Rahmen
        gesetzlicher Verjährungsfristen nutzen, die bis zu dreißig Jahre
        betragen können; die regelmäßige Verjährungsfrist beträgt drei Jahre.
      </p>
      <h2>Sichere Übertragung Ihrer Daten</h2>
      <p>
        Um die bei uns gespeicherten Daten bestmöglich gegen zufällige oder
        vorsätzliche Manipulationen, Verlust, Zerstörung oder den Zugriff
        unberechtigter Personen zu schützen, setzen wir entsprechende technische
        und organisatorische Sicherheitsmaßnahmen ein. Die Sicherheitslevel
        werden in Zusammenarbeit mit Sicherheitsexperten laufend überprüft und
        an neue Sicherheitsstandards angepasst. Der Datenaustausch von und zu
        unserer Webseite findet jeweils verschlüsselt statt. Als
        Übertragungsprotokoll für betreffende Daten bieten wir für unseren
        Webauftritt HTTPS an, jeweils unter Verwendung der aktuellen
        Verschlüsselungsprotokolle. Zudem bieten wir unseren Usern im Rahmen der
        Kontaktformulare sowie bei Bewerbungen eine Inhaltsverschlüsselung an.
        Die Entschlüsselung dieser Daten ist nur uns möglich. Außerdem besteht
        die Möglichkeit, alternative Kommunikationswege zu nutzen (z.B. den
        Postweg).
      </p>
      <h2>Verpflichtung zur Bereitstellung der Daten </h2>
      <p>
        Diverse personenbezogene Daten sind für die Begründung, Durchführung und
        Beendigung des Schuldverhältnisses und der Erfüllung der damit
        verbundenen vertraglichen und gesetzlichen Pflichten notwendig. Gleiches
        gilt für die Nutzung unserer Webseite und der verschiedenen Funktionen,
        die diese zur Verfügung stellt. Details dazu haben wir im o.g. Punkt für
        Sie zusammengefasst. In bestimmten Fällen müssen Daten auch aufgrund
        gesetzlicher Bestimmungen erhoben bzw. zur Verfügung gestellt werden.
        Bitte beachten Sie, dass eine Bearbeitung Ihrer Anfrage oder die
        Durchführung des zugrundeliegenden Schuldverhältnisses ohne
        Bereitstellung dieser Daten nicht möglich ist.
      </p>
      <h2>Kategorien, Quellen und Herkunft der Daten</h2>
      <p>
        Welche Daten wir verarbeiten, bestimmt der jeweilige Kontext: Dies hängt
        davon ab, ob Sie z.B. eine Anfrage in unser Kontaktformular eingeben.
        Bitte beachten Sie, dass wir Informationen für besondere
        Verarbeitungssituationen gegebenenfalls auch gesondert an geeigneter
        Stelle zur Verfügung stellen, z.B. bei einer Kontaktanfrage.
      </p>
      <p>
        Beim Besuch unserer Webseite erheben und verarbeiten wir folgende Daten:
        <ul>
          <li>Name des Internet Service-Providers</li>
          <li>Angaben über die Webseite von der aus Sie uns besuchen</li>
          <li>Verwendeter Webbrowser und verwendetes Betriebssystem</li>
          <li>
            Die von Ihrem Internet Service Provider zugewiesene IP-Adresse
          </li>
          <li>
            Angeforderte Dateien, übertragene Datenmenge, Downloads/Dateiexport
          </li>
          <li>
            Angaben über die Webseiten, die Sie bei uns aufrufen inkl. Datum und
            Uhrzeit
          </li>
        </ul>
        Aus Gründen der technischen Sicherheit (insbesondere zur Abwehr von
        Angriffsversuchen auf unseren Webserver) werden diese Daten gemäß Art. 6
        Absatz 1 lit. f EU-DS-GVO gespeichert. Nach spätestens 7 Tagen findet
        eine Anonymisierung durch Verkürzung der IP-Adresse statt, so dass kein
        Bezug zum Nutzer hergestellt wird.
      </p>
      <h5>
        Im Rahmen einer Kontaktanfrage erheben und verarbeiten wir folgende
        Daten
      </h5>
      <p>
        <ul>
          <li>Name, Vorname</li>
          <li>Kontaktdaten</li>
          <li>Anrede</li>
          <li>Angaben zu Wünschen und Interessen</li>
          <li>Ihre IP-Adresse</li>
        </ul>
      </p>
      <h5>Bei Newslettern erheben und verarbeiten wir folgende Daten</h5>
      <p>
        <ul>
          <li>Name, Vorname</li>
          <li>E-Mail-Adresse</li>
          <li>Anrede</li>
          <li>Ihre IP-Adresse</li>
        </ul>
      </p>
      <h2>
        Kontaktformular / Kontaktaufnahme per E-Mail (Art. 6 Abs. 1 lit. a, b
        EU-DS-GVO)
      </h2>
      <p>
        Auf unserer Webseite ist ein Kontaktformular vorhanden, das für die
        elektronische Kontaktaufnahme genutzt werden kann. Wenn Sie uns über das
        Kontaktformular schreiben, verarbeiten wir Ihre im Rahmen des
        Kontaktformulars angegebenen Daten zur Kontaktaufnahme und Beantwortung
        Ihrer Fragen und Wünsche. Hierbei wird der Grundsatz der
        Datensparsamkeit und Datenvermeidung beachtet, indem Sie nur die Daten
        angeben müssen, die wir zwingend zur Kontaktaufnahme von Ihnen
        benötigen. Dies sind Ihr Name, Ihre E-Mail-Adresse sowie das
        Nachrichtenfeld und der Name des Unternehmens. Zu-dem wird aus
        technischer Notwendigkeit sowie zur rechtlichen Absicherung Ihre
        IP-Adresse verar-beitet. Alle übrigen Daten sind freiwillige Felder und
        können optional (z.B. zur individuelleren Beant-wortung ihrer Fragen)
        angegeben werden. Sofern Sie sich per E-Mail an uns wenden, werden wir
        die in der E-Mail mitgeteilten personenbezogenen Daten allein zum Zweck
        der Bearbeitung Ihrer Anfrage verarbeiten. Sofern Sie die angebotenen
        Formulare zur Kontaktaufnahme nicht nutzen, findet keine
        darüberhinausgehende Datenerhebung statt.
      </p>
      <h2>Umfragen, Studien und Whitepaper (Art. 6 Abs. 1 lit. a EU-DS-GVO)</h2>
      <p>
        Auf unserer Webseite werden von Zeit zu Zeit verschiedene Umfragen,
        Studien und Whitepaper angeboten. Für die Durchführung der Umfragen
        verwenden wir das Tool „LimeSurvey“ des Anbieters LimeSurvey GmbH,
        Papenreye 63 in 22453 Hamburg, welches auf einem unserer eigenen Server
        betrieben wird. Die von Ihnen im Zuge der Umfrage bzw. des
        Download-Vorgangs eingegebenen Daten (Vorname, Nachname, Adresse,
        Telefonnummer, E-Mail-Adresse sowie Ihre IP-Adresse) werden von uns
        ausschließlich basierend auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
        EU-DS-GVO) verarbeitet. Diese Daten nutzen wir sowohl um Ihnen die
        angeforderten Dokumente - wie beispielsweise Umfrageer-gebnisse oder
        Whitepaper - zukommen zu lassen als auch für interne statistische
        Auswertungen. Weiterhin werden Sie in einen themenspezifischen
        Newsletter aufgenommen, der Ihnen regelmäßig ausführliche Informationen
        zusendet. Selbstverständlich können Sie das Abonnement dieses
        Newsletters über die vorgesehenen Abmeldemöglichkeiten beenden und somit
        Ihre Einwilligung – auch in Bezug auf die Newsletter-Analyse –
        widerrufen. Ihren Widerruf können Sie jederzeit per E-Mail an{" "}
        <a href="mailto:support@beestate.io">support@beestate.io</a> bzw. durch
        Klick auf den jedem Newsletter bereitgestellten Link ausüben.
      </p>
      <h2>Customer-Relationship-Management-System „Pipedrive“</h2>
      <p>
        Bei einer Kontaktaufnahme mit uns (beispielsweise per Kontaktformular
        oder via E-Mail) werden zu deren Bearbeitung und Abwicklung die Angaben
        des Nutzers gem. Art. 6 Abs. 1 lit. a oder b DS-GVO verarbeitet. Um Ihre
        Anliegen bestmöglich bearbeiten zu können setzen wir das
        Customer-Relationship-Management-Tool („CRM-Tool“) Pipedrive ein. Der
        Einsatz des CRM-Systems Pipedrive (des Anbieters Pipedrive OÜ – einer
        Gesellschaft mit beschränkter Haftung aus der Republik Estland) erfolgt
        auf Grundlage unserer berechtigten Interessen (schnelle und effiziente
        Bearbeitung von Nutzeranfragen, Bestandskundenmanagement und
        Neukundengeschäft) gem. Art. 6 Abs. 1 lit. f DSG-VO. Mit dem Anbieter
        wurde das datenschutzrechtlich erforderliche Vertragswerk abgeschlossen.
        Die Datenschutzerklärung von Pipedrive können Sie hier abrufen:
        https://www.pipedrive.com/en/privacy. Die Löschung ihrer Daten aus
        unserem CRM-Tool erfolgt wenn wir Ihre Anfrage bearbeitet haben und der
        Zweck der Speicherung entfallen ist sowie keine anderweitigen
        entgegenstehenden gesetz-lichen Ausnahmen vorliegen.
      </p>
      <h2>Newsletter (Art. 6 Abs. 1 lit. a EU-DS-GVO)</h2>
      <p>
        Auf unserer Webseite kann ein kostenfreier Newsletter abonniert werden.
        Die bei der Newsletter-Anmeldung angegebene E-Mail-Adresse sowie Ihr
        Name werden für den Versand des personalisierten Newsletters verwendet.
        Hierbei wird der Grundsatz der Datensparsamkeit und Datenvermeidung
        beachtet, da lediglich die E-Mail-Adresse sowie Ihr Name als Pflichtfeld
        gekennzeichnet sind. Aus technischer Notwendigkeit sowie zur rechtlichen
        Absicherung wird bei Bestellung des Newsletters auch Ihre IP-Adresse
        verar-beitet. Newsletter-Versand über MAILCHIMP Der Versand unserer
        Newsletter erfolgt über den technischen Dienstleister The Rocket Science
        Group, LLC d/b/a MailChimp, 675 Ponce de Leon Ave NE, Suite 5000,
        Atlanta, GA 30308, USA (http://www.mailchimp.com/), an die wir Ihre bei
        der Newsletteranmeldung bereitgestellten Daten weitergeben. Diese
        Weitergabe erfolgt gemäß Art. 6 Abs. 1 lit. a DSGVO aufgrund Ihrer mit
        der Newsletter-Anmeldung erklärten Einwilligung. Bitte beachten Sie,
        dass Ihre Daten in der Regel an einen Server von MailChimp in den USA
        übertragen und dort gespeichert werden. Mit dem Anbieter wurde das
        datenschutzrechtlich erforderliche Vertragswerk abgeschlossen. <br />
        MailChimp verwendet diese Informationen zum Versand und zur
        statistischen Auswertung der Newsletter in unserem Auftrag. Für die
        Auswertung beinhalten die versendeten E-Mails sog. Web-Beacons bzw.
        Trackings-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf unserer
        Website gespei-chert sind. So kann festgestellt werden, ob eine
        Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden.
        Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt des
        Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Die Daten werden
        ausschließlich pseudonymisiert erhoben und werden nicht mir Ihren
        weiteren persönlichen Daten verknüpft, eine direkte
        Personenbeziehbarkeit wird ausgeschlossen. Diese Daten dienen
        ausschließlich der statistischen Analyse von Newsletterkampagnen. Die
        Ergebnisse dieser Analysen können genutzt werden, um künftige Newsletter
        besser an die Interessen der Empfänger anzupassen. <br />
        Wir weisen Sie darauf hin, dass MailChimp Ihre Daten gemäß Art. 6 Abs. 1
        lit. f DSGVO ggf. selbst aufgrund seines eigenen berechtigten Interesses
        an der bedarfsgerechten Ausgestaltung und der Optimierung des Dienstes
        sowie zu Marktforschungszwecken nutzen kann, um etwa zu bestimmen, aus
        welchen Ländern die Empfänger kommen. MailChimp nutzt die Daten unserer
        Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder sie
        an Dritte weiterzugeben. Auf diese Datenverarbeitung durch MailChimp
        haben wir jedoch keinen Einfluss, sodass Sie sich diesbezüglich direkt
        an MailChimp wenden müssen. Des Weiteren bietet MailChimp verschiedene
        Analysemöglichkeiten darüber, wie die versandten Newsletter geöffnet und
        benutzt werden, z.B. an wie viele Nutzer eine E-Mail versendet wurde, ob
        E-Mails zurückgewiesen wurden und ob sich Nutzer nach Erhalt einer
        E-Mail von der Liste abgemeldet haben. Diese Analysen sind jedoch nur
        Gruppenbezogen und werden von uns nicht zur indi-viduellen Auswertung
        verwendet. Weitere Informationen zum Datenschutz bei MailChimp finden
        Sie unter: http://mailchimp.com/legal/privacy/ Sie können
        selbstverständlich das Abonnement jederzeit über die im Newsletter
        vorgesehene Abmeldemöglichkeit beenden und somit Ihre Einwilligung –
        auch in Bezug auf die Newsletter-Analyse – widerrufen. Ihren Widerruf
        können Sie jederzeit per Email an{" "}
        <a href="mailto:support@beestate.io">support@beestate.io</a> bzw. durch
        Klick auf den jedem Newsletter bereitgestellten Link ausüben.
      </p>
      <h2>Automatisierte Einzelfallentscheidungen </h2>
      <p>
        Wir nutzen keine rein automatisierten Verarbeitungsprozesse zur
        Herbeiführung einer Entscheidung.
      </p>
      <h2>
        Cookies (Art. 6 Abs. 1 lit. f EU-DS-GVO / Art. 6 Abs. 1 lit a EU-DS-GVO
        bei Einwilligung)
      </h2>
      <p>
        Unsere Website verwendet an mehreren Stellen sogenannte „Cookies“. Unter
        einem Cookie versteht man eine kleine Textdatei, die vom Server auf
        Ihren Rechner übertragen wird und bestimmte Daten wie IP-Adresse,
        Browser, Betriebssystem, usw. enthalten kann. Cookies starten niemals
        Programme und übertragen keine Schadsoftware. Sie ermöglichen lediglich
        eine effizientere Nutzung sowie die Verbesserung unserer Angebote
        (optionale Cookies) und sind für bestimmte Features unerlässlich
        (technisch notwendige Cookies). Zudem haben wir durch die Cookies die
        Möglichkeit, die Effektivität einer bestimmten Anzeige zu messen und
        ihre Platzierung beispielsweise in Abhängigkeit von den thematischen
        Nutzerinteressen erfolgen zu lassen. Die meisten der von uns verwendeten
        Cookies sind so genannte „Session-Cookies“. Diese werden nach Ihrem
        Besuch automatisch gelöscht. Permanente Cookies werden automatisch von
        Ihrem Computer gelöscht, wenn deren Geltungsdauer (im Regelfall sechs
        Monate) erreicht ist oder Sie diese vor Ablauf der Geltungsdauer selbst
        löschen. Die Rechtsgrundlage für das Ablegen aller nicht technisch
        notwendigen Cookies auf ihrem Rechner ist Ihre vorherige Einwilligung
        gem. Art 6 Abs. 1 S. 1 lit. a DSGVO. Um Ihnen diese Auswahl zu
        vereinfachen verwenden wir das Consent-Tool „Cookiebot“. Weiterhin haben
        Sie die Möglichkeit ihren Browser so zu konfigurieren, dass er Sie vor
        dem Setzen von Cookies informiert oder deren Annahme vollständig
        verweigert. Auch die Löschung bereits gespeicherter Cookies ist
        jederzeit möglich. Für die erwähnten Funktionen müssen Sie in Ihrem
        Browser die entsprechenden Einstellungen anpassen. Informieren Sie sich
        hierfür über die Hilfsfunktion Ihres entsprechenden Browsers oder über
        die Website des entsprechenden Herstellers. Wir weisen allerdings darauf
        hin, dass durch die Deaktivierung von Cookies einige Funktionen dieser
        Website beeinträchtigt werden können und Ihr Nutzungskomfort
        eingeschränkt werden kann.
      </p>
      <h2>Nutzerprofile / Webtracking-Verfahren </h2>
      <h5>GOOGLE Analytics</h5>
      <p>
        Diese Website verwendet Google Analytics. Dieser Dienst wird von Google
        Ireland Limited ("Google"), einer nach irischem Recht eingetragenen und
        betriebenen Gesellschaft (Registernummer: 368047) mit Sitz in Gordon
        House, Barrow Street, Dublin 4, Irland, angeboten. Mit dem Anbieter
        wurde das datenschutzrechtlich erforderliche Vertragswerk abgeschlossen.
        Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem
        Computer gespeichert wer-den und die eine Analyse der Benutzung der
        Website durch Sie ermöglichen. Die durch das Cookie erzeugten
        Informationen über Ihre Benutzung dieser Website (einschließlich der
        gekürzten IP-Adresse) werden in der Regel an einen Server von Google in
        den USA übertragen und dort gespeichert. Diese Website verwendet Google
        Analytics ausschließlich mit der Erweiterung „_anonymizeIp()“, die eine
        Anonymisierung der IP-Adresse durch Kürzung sicherstellt und eine
        direkte Personenbeziehbarkeit ausschließt. Durch die Erweiterung wird
        Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
        den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
        wird die volle IP-Adresse an einen Server von Google in den USA
        übertragen und dort gekürzt. Die Verarbeitung Ihrer personenbezogenen
        Daten durch Google Analytics basiert auf Ihrer Einwilligung gem. Art 6
        Abs. 1 S. 1 lit. a DSGVO. In unserem Auftrag wird Google diese
        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
        Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        uns gegenüber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
        Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
        zusammengeführt. Sie können die Speicherung der Cookies durch eine
        entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
        und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
        IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhin-dern, indem Sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:
        http://tools.google.com/dlpage/gaoptout?hl=de Alternativ zum
        Browser-Plugin oder innerhalb von Browsern auf mobilen Geräten klicken
        Sie bitte auf den folgenden Link, um ein Opt-Out-Cookie zu setzen, der
        die Erfassung durch Google Analytics innerhalb dieser Website zukünftig
        verhindert (dieses Opt-Out-Cookie funktioniert nur in diesem Browser und
        nur für diese Domain, löschen Sie Ihre Cookies in diesem Browser, müssen
        Sie die-sen Link erneut klicken): Google Analytics deaktivieren Diese
        Website verwendet Google Analytics zudem für eine geräteübergreifende
        Analyse von Be-sucherströmen, die über eine User-ID durchgeführt wird.
        Sie können in Ihrem Kundenkonto unter „Meine Daten“, „persönliche Daten“
        die geräteübergreifende Analyse Ihrer Nutzung deaktivieren. Mehr
        Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie
        in der Datenschutzerklärung von Google:
        https://support.google.com/analytics/answer/6004245?hl=de Weitere
        Informationen zur Datennutzung durch Google, Einstellungs- und
        Widerspruchsmöglichkei-ten, sind nachzulesen in der Datenschutzerklärung
        von Google (https://policies.google.com/technologies/ads) bzw. in den
        Einstellungen durch Google
        (https://adssettings.google.com/authenticated). Die personenbezogenen
        Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.
      </p>
      <h5>Google AdWords mit Conversion-Tracking</h5>
      <p>
        Diese Website verwendet die Werbe-Komponente Google Adwords. Dieser
        Dienst wird von Google Ireland Limited ("Google"), einer nach irischem
        Recht eingetragenen und betriebenen Gesellschaft (Registernummer:
        368047) mit Sitz in Gordon House, Barrow Street, Dublin 4, Irland,
        angeboten. Wie bereits erwähnt wurden mit Google das
        datenschutzrechtlich erforderliche Vertragswerk abge-schlossen. Wir
        nutzen das Conversion-Tracking zur zielgerichteten Bewerbung unseres
        Angebots. Als Rechts-grundlage der Verarbeitung Ihrer personenbezogenen
        Daten durch Google Adwords dient Ihre erteilte Einwilligung gem. Art 6
        Abs. 1 S. 1 lit. a DSGVO. Falls Sie auf eine von Google geschaltete
        Anzeige klicken, speichert das von uns eingesetzte Con-version-Tracking
        ein Cookie auf Ihrem Endgerät. Diese sog. Conversion-Cookies verlieren
        mit Ab-lauf von 30 Tagen ihre Gültigkeit und dienen im Übrigen nicht
        Ihrer persönlichen Identifikation. Sofern das Cookie noch gültig ist und
        Sie eine bestimmte Seite unseres Internetauftritts besuchen, können
        sowohl wir als auch Google auswerten, dass Sie auf eine unserer bei
        Google platzierten Anzeigen geklickt haben und dass Sie anschließend auf
        unseren Internetauftritt weitergeleitet worden sind. Durch die so
        eingeholten Informationen erstellt Google uns eine Statistik über den
        Besuch unseres Internetauftritts. Zudem erhalten wir hierdurch
        Informationen über die Anzahl der Nutzer, die auf unsere Anzeige(n)
        geklickt haben sowie über die anschließend aufgerufenen Seiten unseres
        Inter-netauftritts. Weder wir noch Dritte, die ebenfalls Google-AdWords
        einsetzten, werden hierdurch allerdings in die Lage versetzt, Sie auf
        diesem Wege zu identifizieren. Durch die entsprechenden Einstellungen
        Ihres Internet-Browsers können Sie zudem die Installation der Cookies
        verhindern oder einschränken. Gleichzeitig können Sie bereits
        gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen
        Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten
        Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die
        Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden
        sich an dessen Hersteller bzw. Support. Ferner bietet auch Google unter
        • https://services.google.com/sitestats/de.html •
        http://www.google.com/policies/technologies/ads/ •
        http://www.google.de/policies/privacy/ weitergehende Informationen zu
        diesem Thema und dabei insbesondere zu den Möglichkeiten der
        Unterbindung der Datennutzung an.
      </p>
      <h5>Google Tag Manager</h5>
      <p>
        Diese Website setzt den Google Tag Manager ein. Dieser Dienst wird von
        Google Ireland Limited ("Google"), einer nach irischem Recht
        eingetragenen und betriebenen Gesellschaft (Registernum-mer: 368047) mit
        Sitz in Gordon House, Barrow Street, Dublin 4, Irland, angeboten. Wie
        zuvor bereits erwähnt wurden mit Google das datenschutzrechtlich
        erforderliche Vertragswerk abgeschlossen. Durch Einsatz dieses Dienstes
        können Website-Tags über eine Oberfläche verwaltet werden. Hierzu werden
        weder Cookies eingesetzt noch personenbezogene Daten erfasst. Jedoch
        sorgt der Google Tag Manager für die Auslösung weiterer Tags, die unter
        Umständen Daten erfassen können. Darauf weisen wir hiermit gesondert
        hin. Auf diese Daten greift der Tag Manager jedoch nicht zu. Sofern vom
        Nutzer auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen
        wurde, bleibt diese für sämtliche Tracking-Tags bestehen, die mit Google
        Tag Manager implementiert wurden.
      </p>
      <h2>Social Plugins sozialer Netzwerke </h2>
      <p>Es werden zurzeit keine Social Plugins verwendet.</p>
      <h2>Onlineangebote bei Kindern </h2>
      <p>
        Personen unter 16 Jahren dürfen ohne Zustimmung der
        Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln
        bzw. eine Einwilligungserklärung abgeben. Wir möchten Eltern und
        Erziehungsberechtigte dazu auffordern, an den Online-Aktivitäten und
        -Interessen ihrer Kinder aktiv teilzunehmen.
      </p>
      <h2>Links zu anderen Anbietern </h2>
      <p>
        Unsere Webseite enthält - deutlich erkennbar - auch Links auf die
        Internet-Auftritte anderer Unternehmen. Soweit Links zu Webseiten
        anderer Anbieter vorhanden sind, haben wir auf deren Inhalte keinen
        Einfluss. Daher kann für diese Inhalte auch keine Gewähr und Haftung
        übernommen wer-den. Für die Inhalte dieser Seiten ist stets der
        jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
        Rechtsverstöße und erkennbare Rechtsverletzungen geprüft. Rechtswidrige
        Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
        permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
        konkre-te Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend
        entfernt.
      </p>
    </div>
  );
}
