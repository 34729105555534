import React, { useCallback, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import "./Search.css";
import SearchClearBtn from "./SearchClearBtn";

function Search(props) {
  const { onSearch } = props;
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);

  const onEnter = (event, callback) => event.key === "Enter" && callback();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(() => {
      searchEps();
    }, 1000),
    []
  );

  const userInputChanged = () => {
    debouncedSearch();
  };

  const searchEps = () => {
    let in1 = input1.current.value;
    let in2 = input2.current.value;
    let in3 = input3.current.value;
    if (onSearch) {
      onSearch({ in1, in2, in3 });
    }
  };

  const enterPressed = () => {
    if (debouncedSearch) {
      debouncedSearch.cancel();
    }
    searchEps();
  };

  const clearInput = () => {
    input1.current.value = "";
    input2.current.value = "";
    input3.current.value = "";
    searchEps();
    input1.current.focus();
  };

  return (
    <>
      <div className="flex-search">
        <TextField
          autoFocus={true}
          inputRef={input1}
          label="Suchtext 1"
          variant="outlined"
          size="small"
          onKeyPress={(e) => onEnter(e, enterPressed)}
          onChange={() => userInputChanged()}
        />
        <span className="splitting-span">{"&"}</span>
        <TextField
          inputRef={input2}
          label="Suchtext 2"
          variant="outlined"
          size="small"
          onKeyPress={(e) => onEnter(e, enterPressed)}
          onChange={() => userInputChanged()}
        />
        <span className="splitting-span">{"&"}</span>
        <TextField
          inputRef={input3}
          label="Suchtext 3"
          variant="outlined"
          size="small"
          onKeyPress={(e) => onEnter(e, enterPressed)}
          onChange={() => userInputChanged()}
        />
        <SearchClearBtn onSearch={searchEps} onClear={clearInput} />
      </div>
    </>
  );
}

export default Search;
