import { CREATE_NOTIFICATION, CLEAR_NOTIFICATION } from "./notificationTypes";

const initialState = {
  show: false,
  summary: undefined,
  detail: undefined,
  life: 3000,
  showLoadingGlobal: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        show: true,
        summary: action.payload.summary,
        detail: action.payload.detail,
        life: action.payload.life,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        show: false,
        summary: undefined,
        detail: undefined,
        life: 3000,
      };
    default:
      return state;
  }
};

export default notificationReducer;
