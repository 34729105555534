import {
  SHOW_SEARCH,
  SHOW_BROWSE,
  SHOW_SETTINGS,
  HIDE_SETTINGS,
  ADAPT_DISPLAY_OPTIONS,
  ADAPT_SEARCH_OPTIONS,
  ADAPT_COPY_OPTIONS,
} from "./appTypes";
import { OPTION_COPY_EP } from "../../helper/constants";

const initialState = {
  showSearch: true,
  showBrowse: false,
  showSettings: false,
  displayOptions: {
    showKostengruppe: true,
    showKlasse: true,
    showAnlage: true,
    showArt: true,
    showBandbreite: true,
    showEpCode: true,
    showGefmaCode: false,
    showEinheit: false,
    showVortext: false,
    showNachtext: false,
  },
  searchOptions: {
    searchDate: new Date(),
    searchCaseSensitive: false,
    searchMSKategorie1Title: true,
    searchMSKategorie1ShortText: true,
    searchMSKategorie1Description: true,
    searchMSKategorie2Title: true,
    searchMSKategorie2ShortText: true,
    searchMSKategorie2Description: true,
    searchMSKategorie3Title: true,
    searchMSKategorie3ShortText: true,
    searchMSKategorie3Description: true,
    searchKostengruppeId: true,
    searchKostengruppeDescription: true,
    searchKlasseId: true,
    searchKlasseDescription: true,
    searchAnlageId: true,
    searchAnlageDescription: true,
    searchArtId: true,
    searchArtDescription: true,
    searchBandbreiteId: true,
    searchBandbreiteDescription: true,
    searchUnitNumber: true,
    searchGefmaCode: true,
    searchEinheit: true,
    searchVortext: true,
    searchSchlagworte: true,
    searchNachtext: true,
    searchEpCode: false,
    searchKalkulationstyp: false,
    searchIntervall: false,
    searchStundensatz: false,
    searchProjektfaktor: false,
    searchLeistungsbereichfakto: false,
  },
  copyOption: OPTION_COPY_EP,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SEARCH:
      return {
        ...state,
        showSearch: true,
        showBrowse: false,
        //no change at showSettings
      };
    case SHOW_BROWSE:
      return {
        ...state,
        showSearch: false,
        showBrowse: true,
        //no change at showSettings
      };
    case SHOW_SETTINGS:
      return {
        ...state,
        //no change at showSearch
        //no change at showBrowse
        showSettings: true,
      };
    case HIDE_SETTINGS:
      return {
        ...state,
        //no change at showSearch
        //no change at showBrowse
        showSettings: false,
      };
    case ADAPT_DISPLAY_OPTIONS:
      return {
        ...state,
        //no change at showSearch
        //no change at showBrowse
        displayOptions: action.payload,
      };
    case ADAPT_SEARCH_OPTIONS:
      return {
        ...state,
        //no change at showSearch
        //no change at showBrowse
        searchOptions: action.payload,
      };
    case ADAPT_COPY_OPTIONS:
      return {
        ...state,
        copyOption: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
