import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storageSession from "redux-persist/lib/storage/session";
//import storage from "redux-persist/lib/storage";
import epDataReducer from "./ep_data/epDataReducer";
import notificationReducer from "./notification/notificationReducer";
import appReducer from "./app/appReducer";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["data", "notify", "app"],
  transforms: [
    encryptTransform({
      secretKey: "ebFBK9S2c9dwuxXV",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const rootReducer = combineReducers({
  data: epDataReducer,
  notify: notificationReducer,
  app: appReducer,
});

export default persistReducer(persistConfig, rootReducer);
