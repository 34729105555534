import React from "react";
import "./Header.css";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import {
  showSearchUI,
  showBrowseUI,
  showSettingsUI,
  hideSettingsUI,
} from "../../redux/app/appActions";
import {
  clearEpSearchData,
  clearEpBrowseData,
} from "../../redux/ep_data/epDataActions";
import { useHistory } from "react-router-dom";

function Header(props) {
  const history = useHistory();

  const toggleViewMode = () => {
    if (props.searchVisible) {
      props.showBrowseUI();
      props.clearSearchData();
      history.push("/browse");
    } else {
      props.showSearchUI();
      props.clearBrowseData();
      history.push("/search");
    }
  };

  const toggleOptions = () => {
    if (props.showVisible) {
      props.hideSettingsUI();
    } else {
      props.showSettingsUI();
    }
  };

  const injectViewStatus = () => {
    return (
      <div>
        <Button
          className="in_header_btn"
          title="Modus ändern"
          onClick={(e) => toggleViewMode()}
        >
          {props.searchVisible ? (
            <i className="pi pi-eye table_btn_icon" />
          ) : (
            <i className="pi pi-search table_btn_icon" />
          )}
        </Button>
        <span className="in_header_status">
          {props.searchVisible ? "Browse EP" : "Search EP"}
        </span>
      </div>
    );
  };

  const injectOptions = () => {
    return (
      <div>
        <span className="in_header_status">Optionen</span>
        <Button
          className="in_header_btn"
          title="Optionen einstellen"
          onClick={(e) => toggleOptions()}
        >
          <i className="pi pi-cog table_btn_icon" />
        </Button>
      </div>
    );
  };

  const injectHeadline = () => {
    return props.searchVisible ? (
      <h1 className="bee-headline">EP-Search - powered by Beestate ©</h1>
    ) : (
      <h1 className="bee-headline">EP-Browse - powered by Beestate ©</h1>
    );
  };

  return (
    <div className="bee-header">
      {injectViewStatus()}
      {injectHeadline()}
      {injectOptions()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchVisible: state.app.showSearch,
    showVisible: state.app.showSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showBrowseUI: () => dispatch(showBrowseUI()),
    showSearchUI: () => dispatch(showSearchUI()),
    showSettingsUI: () => dispatch(showSettingsUI()),
    hideSettingsUI: () => dispatch(hideSettingsUI()),
    clearSearchData: () => dispatch(clearEpSearchData()),
    clearBrowseData: () => dispatch(clearEpBrowseData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
