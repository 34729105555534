import React from "react";
import "./EpDetail.css";

function EpDetail(props) {
  const { data } = props;

  const NO_CATEGORY_KENNUNG = "00";
  const NO_CATEGORY_TEXT = "- keine Kategorisierung -";
  const NO_CATEGORY_BESCHREIBUNG = "";
  const NO_CATEGORY_KURZTEXT = "- nicht bekannt -";
  const NO_EP_KENNUNG = "00";
  const NO_EP_NAME = "- nicht vergeben -";
  const NO_GEFMA = "- nicht bekannt -";
  const NO_EINHEIT = "- nicht bekannt -";
  const NO_INTERVAL = "- nicht bekannt -";
  const NO_VORTEXT = "";
  const NO_NACHTEXT = "";
  const NO_KALKULATIONSTYP = "- nicht bekannt -";
  const NO_STUNDENSATZ = "- nicht bekannt -";
  const NO_PROJEKTFAKTOR = "- nicht bekannt -";
  const NO_LEISTUNGSBEREICHFAKTOR = "- nicht bekannt -";
  const NO_EPCODE = "- nicht bekannt -";

  return (
    <div className="bee-ep-detail">
      <table>
        <tr>
          <td>
            <span className="ep-detail-header">Kategorie 1:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [
              {data.kategorie_gruppe_kennung
                ? data.kategorie_gruppe_kennung
                : NO_CATEGORY_KENNUNG}
              ]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.kategorie_gruppe_name
                ? data.kategorie_gruppe_name
                : NO_CATEGORY_TEXT}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <span className="ep-detail-descr">
              {data.kategorie_gruppe_beschreibung
                ? data.kategorie_gruppe_beschreibung
                : NO_CATEGORY_BESCHREIBUNG}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Kategorie 2:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [
              {data.kategorie_titel_kennung
                ? data.kategorie_titel_kennung
                : NO_CATEGORY_KENNUNG}
              ]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.kategorie_titel_name
                ? data.kategorie_titel_name
                : NO_CATEGORY_TEXT}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <span className="ep-detail-descr">
              {data.kategorie_titel_beschreibung
                ? data.kategorie_titel_beschreibung
                : NO_CATEGORY_BESCHREIBUNG}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Kategorie 3:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [
              {data.kategorie_untertitel_kennung
                ? data.kategorie_untertitel_kennung
                : NO_CATEGORY_KENNUNG}
              ]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.kategorie_untertitel_name
                ? data.kategorie_untertitel_name
                : NO_CATEGORY_TEXT}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <span className="ep-detail-descr">
              {data.kategorie_untertitel_beschreibung
                ? data.kategorie_untertitel_beschreibung
                : NO_CATEGORY_BESCHREIBUNG}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Kurztext der Kategorie:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.kategorie_kurztext
                ? data.kategorie_kurztext
                : NO_CATEGORY_KURZTEXT}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Kostengruppe:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [
              {data.kostengruppe_kennung
                ? data.kostengruppe_kennung
                : NO_EP_KENNUNG}
              ]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.kostengruppe_name ? data.kostengruppe_name : NO_EP_NAME}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Klasse:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [{data.klasse_kennung ? data.klasse_kennung : NO_EP_KENNUNG}]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.klasse_name ? data.klasse_name : NO_EP_NAME}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Anlage:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [{data.anlage_kennung ? data.anlage_kennung : NO_EP_KENNUNG}]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.anlage_name ? data.anlage_name : NO_EP_NAME}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Art:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [{data.art_kennung ? data.art_kennung : NO_EP_KENNUNG}]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.art_name ? data.art_name : NO_EP_NAME}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Bandbreite:</span>
          </td>
          <td>
            <span className="ep-detail-number">
              [
              {data.bandbreite_kennung
                ? data.bandbreite_kennung
                : NO_EP_KENNUNG}
              ]
            </span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.bandbreite_name ? data.bandbreite_name : NO_EP_NAME}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Gefma Code:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.gefma_code ? data.gefma_code : NO_GEFMA}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Einheit</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.einheit ? data.einheit : NO_EINHEIT}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Interval:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.intervall_kennung ? data.intervall_kennung : NO_INTERVAL}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Vortext:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.vortext ? data.vortext : NO_VORTEXT}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Nachtext:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.nachtext ? data.nachtext : NO_NACHTEXT}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Kalkulationstyp:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.kalkulationstyp_kennung
                ? data.kalkulationstyp_kennung
                : NO_KALKULATIONSTYP}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Stundensatz:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.stundensatz_kennung
                ? data.stundensatz_kennung
                : NO_STUNDENSATZ}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Projektfaktor:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.projektfaktor_kennung
                ? data.projektfaktor_kennung
                : NO_PROJEKTFAKTOR}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Leistungsbereichfaktor:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.leistungsbereichfaktor_kennung
                ? data.leistungsbereichfaktor_kennung
                : NO_LEISTUNGSBEREICHFAKTOR}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="ep-detail-header">Ep-Code:</span>
          </td>
          <td>
            <span className="ep-detail-number"></span>
          </td>
          <td>
            <span className="ep-detail-content">
              {data.ep_code ? data.ep_code : NO_EPCODE}
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default EpDetail;
