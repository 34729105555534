import { CREATE_NOTIFICATION, CLEAR_NOTIFICATION } from "./notificationTypes";

export const createGlobalNotification = (summary, detail, life) => {
  return {
    type: CREATE_NOTIFICATION,
    payload: {
      summary: summary,
      detail: detail,
      life: life,
    },
  };
};

export const clearGlobalNotifications = () => {
  return {
    type: CLEAR_NOTIFICATION,
  };
};
