import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import EpSearch from "../ui/pages/EpSearch";
import EpBrowse from "../ui/pages/EpBrowse";
import Legal from "../ui/pages/Legal";
import Terms from "../ui/pages/Terms";
import Privacy from "../ui/pages/Privacy";
import Footer from "../ui/components/Footer";
import Header from "../ui/components/Header";
import GlobalNotification from "../ui/components/GlobalNotification";
import GlobalLoadingOverlay from "../ui/components/GlobalLoadingOverlay";
import Options from "../ui/components/Options";
import "./App.css";
import "../layout/layout.scss";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/mdc-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

function App(props) {
  return (
    <div className="App">
      <div className="flex-container bg">
        <Header />
        <Options />
        <Switch>
          <Route exact path="/" component={EpSearch} />
          <Route path="/search" component={EpSearch} />
          <Route path="/browse" component={EpBrowse} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/legal" component={Legal} />
        </Switch>
        <GlobalLoadingOverlay />
        <GlobalNotification />
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(App);
