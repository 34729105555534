import React from "react";
import { connect } from "react-redux";
import "./TableLoadingOverlay.css";

function TableLoadingOverlay(props) {
  const classes = props.visible ? "bee-loading-overlay" : "hidden";
  return props.visible ? <div className={classes}></div> : null;
}

const mapStateToProps = (state) => {
  return {
    visible: state.data.epLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //clearNotification: () => dispatch(clearGlobalNotifications()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableLoadingOverlay);
