import React, { useState } from "react";
import { connect } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { RadioButton } from "primereact/radiobutton";
import {
  adaptDisplayOptions,
  adaptSearchOptions,
  adaptCopyOptions,
  hideSettingsUI,
} from "../../redux/app/appActions";
import "./Options.css";
import {
  OPTION_COPY_EP,
  OPTION_COPY_EXCEL,
  OPTION_COPY_CUSTOM,
} from "../../helper/constants";
import { Button } from "primereact/button";
import {
  clearEpSearchData,
  clearEpBrowseData,
} from "../../redux/ep_data/epDataActions";
import { MIN_DATE_EP_SELECT, MAX_DATE_EP_SELECT } from "../../helper/constants";

function Options(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const adaptDisplayOptions = (
    kostengruppe,
    klasse,
    anlage,
    art,
    bandbreite,
    epCode,
    gefmaCode,
    einheit,
    vortext,
    nachtext
  ) => {
    const newKostengruppe =
      kostengruppe != null
        ? kostengruppe
        : props.displayOptions.showKostengruppe;
    const newKlasse = klasse != null ? klasse : props.displayOptions.showKlasse;
    const newAnlage = anlage != null ? anlage : props.displayOptions.showAnlage;
    const newArt = art != null ? art : props.displayOptions.showArt;
    const newBandbreite =
      bandbreite != null ? bandbreite : props.displayOptions.showBandbreite;
    const newEpCode = epCode != null ? epCode : props.displayOptions.showEpCode;
    const newGefmaCode =
      gefmaCode != null ? gefmaCode : props.displayOptions.showGefmaCode;
    const newEinheit =
      einheit != null ? einheit : props.displayOptions.showEinheit;
    const newVortext =
      vortext != null ? vortext : props.displayOptions.showVortext;
    const newNachtext =
      nachtext != null ? nachtext : props.displayOptions.showNachtext;
    props.changeDisplayOptions(
      newKostengruppe,
      newKlasse,
      newAnlage,
      newArt,
      newBandbreite,
      newEpCode,
      newGefmaCode,
      newEinheit,
      newVortext,
      newNachtext
    );
  };

  const adaptSearchOptions = (
    searchDate,
    searchCaseSensitive,
    searchMSKategorie1Title,
    searchMSKategorie1ShortText,
    searchMSKategorie1Description,
    searchMSKategorie2Title,
    searchMSKategorie2ShortText,
    searchMSKategorie2Description,
    searchMSKategorie3Title,
    searchMSKategorie3ShortText,
    searchMSKategorie3Description,
    searchKostengruppeId,
    searchKostengruppeDescription,
    searchKlasseId,
    searchKlasseDescription,
    searchAnlageId,
    searchAnlageDescription,
    searchArtId,
    searchArtDescription,
    searchBandbreiteId,
    searchBandbreiteDescription,
    searchUnitNumber,
    searchGefmaCode,
    searchEinheit,
    searchVortext,
    searchSchlagworte,
    searchNachtext,
    searchEpCode,
    searchKalkulationstyp,
    searchIntervall,
    searchStundensatz,
    searchProjektfaktor,
    searchLeistungsbereichfaktor
  ) => {
    //clear stored data in case of other search params
    props.clearBrowseData();
    props.clearSearchData();
    //adapt values
    const newSearchDate =
      searchDate != null ? searchDate : props.searchOptions.searchDate;
    const newSearchCaseSensitive =
      searchCaseSensitive != null
        ? searchCaseSensitive
        : props.searchOptions.searchCaseSensitive;
    const newSearchMSKategorie1Title =
      searchMSKategorie1Title != null
        ? searchMSKategorie1Title
        : props.searchOptions.searchMSKategorie1Title;
    const newSearchMSKategorie1ShortText =
      searchMSKategorie1ShortText != null
        ? searchMSKategorie1ShortText
        : props.searchOptions.searchMSKategorie1ShortText;
    const newSearchMSKategorie1Description =
      searchMSKategorie1Description != null
        ? searchMSKategorie1Description
        : props.searchOptions.searchMSKategorie1Description;
    const newSearchMSKategorie2Title =
      searchMSKategorie2Title != null
        ? searchMSKategorie2Title
        : props.searchOptions.searchMSKategorie2Title;
    const newSearchMSKategorie2ShortText =
      searchMSKategorie2ShortText != null
        ? searchMSKategorie2ShortText
        : props.searchOptions.searchMSKategorie2ShortText;
    const newSearchMSKategorie2Description =
      searchMSKategorie2Description != null
        ? searchMSKategorie2Description
        : props.searchOptions.searchMSKategorie2Description;
    const newSearchMSKategorie3Title =
      searchMSKategorie3Title != null
        ? searchMSKategorie3Title
        : props.searchOptions.searchMSKategorie3Title;
    const newSearchMSKategorie3ShortText =
      searchMSKategorie3ShortText != null
        ? searchMSKategorie3ShortText
        : props.searchOptions.searchMSKategorie3ShortText;
    const newSearchMSKategorie3Description =
      searchMSKategorie3Description != null
        ? searchMSKategorie3Description
        : props.searchOptions.searchMSKategorie3Description;
    const newSearchKostengruppeId =
      searchKostengruppeId != null
        ? searchKostengruppeId
        : props.searchOptions.searchKostengruppeId;
    const newSearchKostengruppeDescription =
      searchKostengruppeDescription != null
        ? searchKostengruppeDescription
        : props.searchOptions.searchKostengruppeDescription;
    const newSearchKlasseId =
      searchKlasseId != null
        ? searchKlasseId
        : props.searchOptions.searchKlasseId;
    const newSearchKlasseDescription =
      searchKlasseDescription != null
        ? searchKlasseDescription
        : props.searchOptions.searchKlasseDescription;
    const newSearchAnlageId =
      searchAnlageId != null
        ? searchAnlageId
        : props.searchOptions.searchAnlageId;
    const newSearchAnlageDescription =
      searchAnlageDescription != null
        ? searchAnlageDescription
        : props.searchOptions.searchAnlageDescription;
    const newSearchArtId =
      searchArtId != null ? searchArtId : props.searchOptions.searchArtId;
    const newSearchArtDescription =
      searchArtDescription != null
        ? searchArtDescription
        : props.searchOptions.searchArtDescription;
    const newSearchBandbreiteId =
      searchBandbreiteId != null
        ? searchBandbreiteId
        : props.searchOptions.searchBandbreiteId;
    const newSearchBandbreiteDescription =
      searchBandbreiteDescription != null
        ? searchBandbreiteDescription
        : props.searchOptions.searchBandbreiteDescription;
    const newSearchUnitNumber =
      searchUnitNumber != null
        ? searchUnitNumber
        : props.searchOptions.searchUnitNumber;
    const newSearchGefmaCode =
      searchGefmaCode != null
        ? searchGefmaCode
        : props.searchOptions.searchGefmaCode;
    const newSearchEinheit =
      searchEinheit != null ? searchEinheit : props.searchOptions.searchEinheit;
    const newSearchVortext =
      searchVortext != null ? searchVortext : props.searchOptions.searchVortext;
    const newSearchSchlagworte =
      searchSchlagworte != null
        ? searchSchlagworte
        : props.searchOptions.searchSchlagworte;
    const newSearchNachtext =
      searchNachtext != null
        ? searchNachtext
        : props.searchOptions.searchNachtext;
    const newSearchEpCode =
      searchEpCode != null ? searchEpCode : props.searchOptions.searchEpCode;
    const newSearchKalkulationstyp =
      searchKalkulationstyp != null
        ? searchKalkulationstyp
        : props.searchOptions.searchKalkulationstyp;
    const newSearchIntervall =
      searchIntervall != null
        ? searchIntervall
        : props.searchOptions.searchIntervall;
    const newSearchStundensatz =
      searchStundensatz != null
        ? searchStundensatz
        : props.searchOptions.searchStundensatz;
    const newSearchProjektfaktor =
      searchProjektfaktor != null
        ? searchProjektfaktor
        : props.searchOptions.searchProjektfaktor;
    const newSearchLeistungsbereichfaktor =
      searchLeistungsbereichfaktor != null
        ? searchLeistungsbereichfaktor
        : props.searchLeistungsbereichfaktor;
    //adapt search options
    props.adaptSearchOptions(
      newSearchDate,
      newSearchCaseSensitive,
      newSearchMSKategorie1Title,
      newSearchMSKategorie1ShortText,
      newSearchMSKategorie1Description,
      newSearchMSKategorie2Title,
      newSearchMSKategorie2ShortText,
      newSearchMSKategorie2Description,
      newSearchMSKategorie3Title,
      newSearchMSKategorie3ShortText,
      newSearchMSKategorie3Description,
      newSearchKostengruppeId,
      newSearchKostengruppeDescription,
      newSearchKlasseId,
      newSearchKlasseDescription,
      newSearchAnlageId,
      newSearchAnlageDescription,
      newSearchArtId,
      newSearchArtDescription,
      newSearchBandbreiteId,
      newSearchBandbreiteDescription,
      newSearchUnitNumber,
      newSearchGefmaCode,
      newSearchEinheit,
      newSearchVortext,
      newSearchSchlagworte,
      newSearchNachtext,
      newSearchEpCode,
      newSearchKalkulationstyp,
      newSearchIntervall,
      newSearchStundensatz,
      newSearchProjektfaktor,
      newSearchLeistungsbereichfaktor
    );
  };

  const injectAnzeigeOptionen = () => {
    return (
      <div>
        <h5 className="bee-color-accent bee-spacing-top-small">
          Welche Elemente sollen in der Tabelle angezeigt werden?
        </h5>
        <table>
          <tr>
            <td>
              <Checkbox
                inputId="show_kostengruppe"
                checked={props.displayOptions.showKostengruppe}
                onChange={(e) =>
                  adaptDisplayOptions(
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_kostengruppe" className="p-checkbox-label">
                Kostengruppe
              </label>
            </td>
            <td>
              <Checkbox
                inputId="show_bandbreite"
                checked={props.displayOptions.showBandbreite}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_bandbreite" className="p-checkbox-label">
                Bandbreite
              </label>
            </td>
            <td>
              <Checkbox
                inputId="show_vortext"
                checked={props.displayOptions.showVortext}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null
                  )
                }
              />
              <label htmlFor="show_vortext" className="p-checkbox-label">
                Vortext
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox
                inputId="show_klasse"
                checked={props.displayOptions.showKlasse}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_klasse" className="p-checkbox-label">
                Klasse
              </label>
            </td>
            <td>
              <Checkbox
                inputId="show_ep_code"
                checked={props.displayOptions.showEpCode}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_ep_code" className="p-checkbox-label">
                Ep-Code
              </label>
            </td>
            <td>
              <Checkbox
                inputId="show_nachtext"
                checked={props.displayOptions.showNachtext}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked
                  )
                }
              />
              <label htmlFor="show_nachtext" className="p-checkbox-label">
                Nachtext
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox
                inputId="show_anlage"
                checked={props.displayOptions.showAnlage}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_anlage" className="p-checkbox-label">
                Anlage
              </label>
            </td>
            <td>
              <Checkbox
                inputId="show_gefma_code"
                checked={props.displayOptions.showGefmaCode}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_gefma_code" className="p-checkbox-label">
                Gefma Code
              </label>
            </td>
            <td>
              <Checkbox
                inputId="show_einheit"
                checked={props.displayOptions.showEinheit}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_einheit" className="p-checkbox-label">
                Einheit
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox
                inputId="show_art"
                checked={props.displayOptions.showArt}
                onChange={(e) =>
                  adaptDisplayOptions(
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="show_art" className="p-checkbox-label">
                Art
              </label>
            </td>
          </tr>
          {/* //Parameters Add */}
        </table>
      </div>
    );
  };

  const injectDataOptions = () => {
    return (
      <div>
        <h5 className="bee-color-accent bee-spacing-top-small">
          Zu welchem Zeitpunkt soll der Stand verwendet werden?
        </h5>
        <p className="bee-text-bold bee-spacing">Allgemein</p>
        <div>
          <Calendar
            id="searchDate"
            dateFormat="dd.mm.yy"
            showTime
            showSeconds
            hourFormat="24"
            minDate={MIN_DATE_EP_SELECT}
            maxDate={MAX_DATE_EP_SELECT}
            value={new Date(props.searchOptions.searchDate)}
            onChange={(e) =>
              adaptSearchOptions(
                e.value,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              )
            }
            showIcon
          />
          <label htmlFor="searchDate"> Datum des Standes</label>
        </div>
      </div>
    );
  };

  const injectCopyOptions = () => {
    return (
      <div>
        <h5 className="bee-color-accent bee-spacing-top-small">
          Welche Daten sollen mit dem Copy-Button in die Zwischenablage kopiert
          werden?
        </h5>
        <div className="p-field-radiobutton">
          <RadioButton
            inputId="copyAction1"
            value={OPTION_COPY_EP}
            onChange={(e) => props.changeCopyOptions(e.value)}
            checked={props.copyOption === OPTION_COPY_EP}
          />
          <label htmlFor="copyAction1">EpCode</label>
        </div>
        <div className="p-field-radiobutton">
          <RadioButton
            inputId="copyAction2"
            value={OPTION_COPY_EXCEL}
            onChange={(e) => props.changeCopyOptions(e.value)}
            checked={props.copyOption === OPTION_COPY_EXCEL}
          />
          <label htmlFor="copyAction2">Excel Template</label>
        </div>
        <div className="p-field-radiobutton">
          <RadioButton
            inputId="copyAction3"
            value={OPTION_COPY_CUSTOM}
            disabled={true}
            onChange={(e) => props.changeCopyOptions(e.value)}
            checked={props.copyOption === OPTION_COPY_CUSTOM}
          />
          <label htmlFor="copyAction3">
            Benutzerdefiniert (noch nicht verfügbar)
          </label>
        </div>
      </div>
    );
  };

  const injectSuchOptionen = () => {
    return (
      <div>
        <h5 className="bee-color-accent bee-spacing-top-small">
          Welche Attribute sollen bei der Suche berücksichtigt werden?
        </h5>
        <p className="bee-text-bold bee-spacing">Allgemein</p>
        <div className="bee-spacing-top-small">
          <Checkbox
            inputId="searchCaseSensitive"
            checked={props.searchOptions.searchCaseSensitive}
            onChange={(e) =>
              adaptSearchOptions(
                null,
                e.checked,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              )
            }
          />
          <label htmlFor="searchCaseSensitive" className="p-checkbox-label">
            Groß- und Kleinschreibung beachten
          </label>
        </div>
        <div>
          <p className="bee-text-bold bee-spacing">Kategorien</p>
          <table>
            <tr>
              <th>Gruppe</th>
              <th>Titel</th>
              <th>Untertitel</th>
            </tr>
            <tr>
              <td>
                <Checkbox
                  inputId="searchMSKategorie1Title"
                  checked={props.searchOptions.searchMSKategorie1Title}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie1Title"
                  className="p-checkbox-label"
                >
                  Titel
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchMSKategorie2Title"
                  checked={props.searchOptions.searchMSKategorie2Title}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie2Title"
                  className="p-checkbox-label"
                >
                  Titel
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchMSKategorie3Title"
                  checked={props.searchOptions.searchMSKategorie3Title}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie3Title"
                  className="p-checkbox-label"
                >
                  Titel
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox
                  inputId="searchMSKategorie1ShortText"
                  checked={props.searchOptions.searchMSKategorie1ShortText}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie1ShortText"
                  className="p-checkbox-label"
                >
                  Kurzbeschreibung
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchMSKategorie2ShortText"
                  checked={props.searchOptions.searchMSKategorie2ShortText}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie2ShortText"
                  className="p-checkbox-label"
                >
                  Kurzbeschreibung
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchMSKategorie3ShortText"
                  checked={props.searchOptions.searchMSKategorie3ShortText}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie3ShortText"
                  className="p-checkbox-label"
                >
                  Kurzbeschreibung
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox
                  inputId="searchMSKategorie1Description"
                  checked={props.searchOptions.searchMSKategorie1Description}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie1Description"
                  className="p-checkbox-label"
                >
                  Beschreibung
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchMSKategorie2Description"
                  checked={props.searchOptions.searchMSKategorie2Description}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie2Description"
                  className="p-checkbox-label"
                >
                  Beschreibung
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchMSKategorie3Description"
                  checked={props.searchOptions.searchMSKategorie3Description}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label
                  htmlFor="searchMSKategorie3Description"
                  className="p-checkbox-label"
                >
                  Beschreibung
                </label>
              </td>
            </tr>
          </table>
          <p className="bee-text-bold bee-spacing">MsData</p>
          <tr>
            <th>Kostengruppe</th>
            <th>Anlagenklasse</th>
            <th>Anlage</th>
            <th>Art</th>
            <th>Bandbreite</th>
          </tr>
          <tr>
            <td>
              <Checkbox
                inputId="searchKostengruppeId"
                checked={props.searchOptions.searchKostengruppeId}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                htmlFor="searchKostengruppeId"
                className="p-checkbox-label"
              >
                Code
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchKlasseId"
                checked={props.searchOptions.searchKlasseId}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="searchKlasseId" className="p-checkbox-label">
                Code
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchAnlageId"
                checked={props.searchOptions.searchAnlageId}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="searchAnlageId" className="p-checkbox-label">
                Code
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchArtId"
                checked={props.searchOptions.searchArtId}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="searchArtId" className="p-checkbox-label">
                Code
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchBandbreiteId"
                checked={props.searchOptions.searchBandbreiteId}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label htmlFor="searchBandbreiteId" className="p-checkbox-label">
                Code
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox
                inputId="searchKostengruppeDescription"
                checked={props.searchOptions.searchKostengruppeDescription}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                htmlFor="searchKostengruppeDescription"
                className="p-checkbox-label"
              >
                Beschreibung
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchKlasseDescription"
                checked={props.searchOptions.searchKlasseDescription}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                htmlFor="searchKlasseDescription"
                className="p-checkbox-label"
              >
                Beschreibung
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchAnlageDescription"
                checked={props.searchOptions.searchAnlageDescription}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                htmlFor="searchAnlageDescription"
                className="p-checkbox-label"
              >
                Beschreibung
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchArtDescription"
                checked={props.searchOptions.searchArtDescription}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                htmlFor="searchArtDescription"
                className="p-checkbox-label"
              >
                Beschreibung
              </label>
            </td>
            <td>
              <Checkbox
                inputId="searchBandbreiteDescription"
                checked={props.searchOptions.searchBandbreiteDescription}
                onChange={(e) =>
                  adaptSearchOptions(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    e.checked,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                htmlFor="searchBandbreiteDescription"
                className="p-checkbox-label"
              >
                Beschreibung
              </label>
            </td>
          </tr>
          <p className="bee-text-bold bee-spacing">Sonstiges</p>
          <table>
            <tr>
              <td>
                <Checkbox
                  inputId="searchUnitNumber"
                  checked={props.searchOptions.searchUnitNumber}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label htmlFor="searchUnitNumber" className="p-checkbox-label">
                  UnitNummer
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchGefmaCode"
                  checked={props.searchOptions.searchGefmaCode}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label htmlFor="searchGefmaCode" className="p-checkbox-label">
                  GefmaCode
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchEinheit"
                  checked={props.searchOptions.searchEinheit}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label htmlFor="searchEinheit" className="p-checkbox-label">
                  Einheit
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox
                  inputId="searchVortext"
                  checked={props.searchOptions.searchVortext}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label htmlFor="searchVortext" className="p-checkbox-label">
                  Vortext
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchNachtext"
                  checked={props.searchOptions.searchNachtext}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label htmlFor="searchNachtext" className="p-checkbox-label">
                  Nachtext
                </label>
              </td>
              <td>
                <Checkbox
                  inputId="searchSchlagworte"
                  checked={props.searchOptions.searchSchlagworte}
                  onChange={(e) =>
                    adaptSearchOptions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      e.checked,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  }
                />
                <label htmlFor="searchSchlagworte" className="p-checkbox-label">
                  Schlagworte
                </label>
              </td>
              {/* FIXME ADAPT PARAMETERS */}
            </tr>
          </table>
        </div>
      </div>
    );
  };

  return props.showVisible ? (
    <div className="bee-option-container card">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Anzeigeoptionen">{injectAnzeigeOptionen()}</TabPanel>
        <TabPanel header="Datenoptionen">{injectDataOptions()}</TabPanel>
        <TabPanel header="Suchoptionen">{injectSuchOptionen()}</TabPanel>
        <TabPanel header="Kopieroptionen">{injectCopyOptions()}</TabPanel>
      </TabView>
      <Button
        id="bee-close-settings-btn"
        title="Optionen minimieren"
        onClick={(e) => props.closeSettings()}
      >
        Optionen minimieren
      </Button>
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    showVisible: state.app.showSettings,
    displayOptions: state.app.displayOptions,
    searchOptions: state.app.searchOptions,
    copyOption: state.app.copyOption,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearSearchData: () => dispatch(clearEpSearchData()),
    clearBrowseData: () => dispatch(clearEpBrowseData()),
    changeCopyOptions: (copyOption) => dispatch(adaptCopyOptions(copyOption)),
    closeSettings: () => dispatch(hideSettingsUI()),
    changeDisplayOptions: (
      showKostengruppe,
      showKlasse,
      showAnlage,
      showArt,
      showBandbreite,
      showEpCode,
      showGefmaCode,
      showEinheit,
      showVortext,
      showNachtext
    ) =>
      dispatch(
        adaptDisplayOptions(
          showKostengruppe,
          showKlasse,
          showAnlage,
          showArt,
          showBandbreite,
          showEpCode,
          showGefmaCode,
          showEinheit,
          showVortext,
          showNachtext
        )
      ),
    adaptSearchOptions: (
      searchDate,
      searchCaseSensitive,
      searchMSKategorie1Title,
      searchMSKategorie1ShortText,
      searchMSKategorie1Description,
      searchMSKategorie2Title,
      searchMSKategorie2ShortText,
      searchMSKategorie2Description,
      searchMSKategorie3Title,
      searchMSKategorie3ShortText,
      searchMSKategorie3Description,
      searchKostengruppeId,
      searchKostengruppeDescription,
      searchKlasseId,
      searchKlasseDescription,
      searchAnlageId,
      searchAnlageDescription,
      searchArtId,
      searchArtDescription,
      searchBandbreiteId,
      searchBandbreiteDescription,
      searchUnitNumber,
      searchGefmaCode,
      searchEinheit,
      searchVortext,
      searchSchlagworte,
      searchNachtext,
      searchEpCode,
      searchKalkulationstyp,
      searchIntervall,
      searchStundensatz,
      searchProjektfaktor,
      searchLeistungsbereichfaktor
    ) =>
      dispatch(
        adaptSearchOptions(
          searchDate,
          searchCaseSensitive,
          searchMSKategorie1Title,
          searchMSKategorie1ShortText,
          searchMSKategorie1Description,
          searchMSKategorie2Title,
          searchMSKategorie2ShortText,
          searchMSKategorie2Description,
          searchMSKategorie3Title,
          searchMSKategorie3ShortText,
          searchMSKategorie3Description,
          searchKostengruppeId,
          searchKostengruppeDescription,
          searchKlasseId,
          searchKlasseDescription,
          searchAnlageId,
          searchAnlageDescription,
          searchArtId,
          searchArtDescription,
          searchBandbreiteId,
          searchBandbreiteDescription,
          searchUnitNumber,
          searchGefmaCode,
          searchEinheit,
          searchVortext,
          searchSchlagworte,
          searchNachtext,
          searchEpCode,
          searchKalkulationstyp,
          searchIntervall,
          searchStundensatz,
          searchProjektfaktor,
          searchLeistungsbereichfaktor
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
