import {
  EP_SEARCH_REQUEST,
  EP_SEARCH_SUCCESS,
  EP_SEARCH_FAILURE,
  EP_BROWSE_REQUEST,
  EP_BROWSE_SUCCESS,
  EP_BROWSE_FAILURE,
  EP_CLEAR_SEARCH_DATA,
  EP_CLEAR_BROWSE_DATA,
} from "./epDataActionTypes";
import _ from "lodash";

const initialState = {
  epData: undefined,
  epDataTimestamp: undefined,
  epLoading: false,
  epError: undefined,
  epBrowseTree: undefined,
  epBrowseTreeTimestamp: undefined,
  epBrowseRequestPending: false,
};

const epDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case EP_SEARCH_REQUEST:
      return {
        ...state,
        //dont touch data => show in
        epLoading: true,
        epError: undefined,
        epDataTimestamp: undefined,
      };
    case EP_SEARCH_SUCCESS:
      let sorted = undefined;
      if (action.payload) {
        sorted = _.sortBy(action.payload.data, [
          "kategorie_gruppe_kennung",
          "kategorie_titel_kennung",
          "kategorie_untertitel_kennung",
          "kostengruppe_kennung",
          "klasse_kennung",
          "anlage_kennung",
          "art_kennung",
          "bandbreite_kennung",
        ]);
      }
      return {
        ...state,
        epData: sorted,
        epDataTimestamp: action.payload.timestamp,
        epLoading: false,
        epError: undefined,
      };
    case EP_SEARCH_FAILURE:
      return {
        ...state,
        epData: undefined,
        epDataTimestamp: undefined,
        epLoading: false,
        epError: action.payload,
      };
    case EP_CLEAR_SEARCH_DATA:
      return {
        ...state,
        epData: undefined,
        epDataTimestamp: undefined,
        epLoading: false,
        epError: undefined,
      };
    case EP_BROWSE_REQUEST:
      return {
        ...state,
        epBrowseTree: undefined,
        epBrowseTreeTimestamp: undefined,
        epBrowseRequestPending: true,
      };
    case EP_BROWSE_SUCCESS:
      return {
        ...state,
        epBrowseTree: action.payload.data,
        epBrowseTreeTimestamp: action.payload.timestamp,
        epBrowseRequestPending: false,
      };
    case EP_BROWSE_FAILURE:
      return {
        ...state,
        epBrowseTree: undefined,
        epBrowseTreeTimestamp: undefined,
        epBrowseRequestPending: false,
      };
    case EP_CLEAR_BROWSE_DATA:
      return {
        ...state,
        epBrowseTree: undefined,
        epBrowseTreeTimestamp: undefined,
        epBrowseRequestPending: false,
      };
    default:
      return state;
  }
};

export default epDataReducer;
