import React from "react";
import { connect } from "react-redux";
import "./GlobalLoadingOverlay.css";
import CircularProgress from "@material-ui/core/CircularProgress";

function GlobalLoadingOverlay(props) {
  const classes = props.visible
    ? "bee-global-loading"
    : "bee-global-loading-hidden";
  return (
    <div className={classes}>
      <h3 className="bee-global-loading-header">Daten werden geladen...</h3>
      <CircularProgress />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    visible: state.data.epLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //clearNotification: () => dispatch(clearGlobalNotifications()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalLoadingOverlay);
