export const EP_SEARCH_REQUEST = "EP_SEARCH_REQUEST";
export const EP_SEARCH_SUCCESS = "EP_SEARCH_SUCCESS";
export const EP_SEARCH_FAILURE = "EP_SEARCH_FAILURE";

export const EP_BROWSE_REQUEST = "EP_BROWSE_REQUEST";
export const EP_BROWSE_SUCCESS = "EP_BROWSE_SUCCESS";
export const EP_BROWSE_FAILURE = "EP_BROWSE_FAILURE";

export const EP_CLEAR_SEARCH_DATA = "EP_CLEAR_SEARCH_DATA";
export const EP_CLEAR_BROWSE_DATA = "EP_CLEAR_BROWSE_DATA";
