import React from "react";
import { Button } from "primereact/button";
import "./SearchClearBtn.css";

function SearchClearBtn(props) {
  const { onSearch, onClear } = props;

  const triggerSearch = () => {
    if (onSearch) {
      onSearch();
    }
  };

  const triggerClear = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className="bee-two-sided-btn">
      <Button
        type="button"
        id="bee-two-btn-left"
        icon="pi pi-search"
        title="Starte eine neue Suche"
        onClick={() => triggerSearch()}
      />
      <Button
        type="button"
        id="bee-two-btn-right"
        icon="pi pi-trash"
        title="Lösche alle Eingaben"
        onClick={() => triggerClear()}
      />
    </div>
  );
}

export default SearchClearBtn;
