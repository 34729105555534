import React from "react";
import { connect } from "react-redux";
import { clearGlobalNotifications } from "../../redux/notification/notificationActions";
import "./GlobalNotification.css";

function GlobalNotification(props) {
  const hide = () => {
    setTimeout(
      function () {
        props.clearNotification();
      },
      props.life ? props.life : 2000
    );
  };

  const injectMsg = () => {
    return props.show ? (
      <div className="bee-go-msg">
        {props.summary ? <h1>{props.summary}</h1> : null}
        {props.detail ? <span>{props.detail}</span> : null}
        {hide()}
      </div>
    ) : null;
  };

  return <div className="bee-btm-overlay">{injectMsg()}</div>;
}

const mapStateToProps = (state) => {
  return {
    show: state.notify.show,
    summary: state.notify.summary,
    detail: state.notify.detail,
    life: state.notify.life,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearNotification: () => dispatch(clearGlobalNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotification);
