import React, { useState } from "react";
import { connect } from "react-redux";
import { searchForEp } from "../../redux/ep_data/epDataActions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Search from "../components/Search";
import "./EpSearch.css";
import { createGlobalNotification } from "../../redux/notification/notificationActions";
import TableLoadingOverlay from "../components/TableLoadingOverlay";
import EpDetail from "../components/EpDetail";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { OPTION_COPY_EP, OPTION_COPY_EXCEL } from "../../helper/constants";
import { hideSettingsUI } from "../../redux/app/appActions";

function EpSearch(props) {
  const [expandedRows, setExpandedRows] = useState(null);

  function headerTemplate(data, index) {
    let cat2Text = data.kategorie_titel_name ? (
      <>
        <i className="pi pi-angle-right icon_in_table_subheader" />
        {data.kategorie_titel_name}
      </>
    ) : (
      ""
    );
    let cat3Text = data.kategorie_untertitel_name ? (
      <>
        <i className="pi pi-angle-right icon_in_table_subheader" />
        {data.kategorie_untertitel_name}
      </>
    ) : (
      ""
    );
    return [
      <div key={data.titel_nummer} className="flex">
        {data.titel_nummer} - {data.kategorie_gruppe_name} {cat2Text}
        {cat3Text}
      </div>,
    ];
  }

  function footerTemplate(data, index) {
    return <div className="bee-group-footer">{" . "}</div>;
  }

  function ass1Template(data, index) {
    return [
      <div key={data.kostengruppe_name}>
        <span className="table-data-number">
          [{data.kostengruppe_kennung}]{" "}
        </span>
        <span className="table-data-content">{data.kostengruppe_name}</span>
      </div>,
    ];
  }

  function ass2Template(data, index) {
    return [
      <div key={data.klasse_name}>
        <span className="table-data-number">[{data.klasse_kennung}] </span>
        <span className="table-data-content">{data.klasse_name}</span>
      </div>,
    ];
  }

  function ass3Template(data, index) {
    return [
      <div key={data.anlage_name}>
        <span className="table-data-number">[{data.anlage_kennung}] </span>
        <span className="table-data-content">{data.anlage_name}</span>
      </div>,
    ];
  }

  function ass4Template(data, index) {
    return [
      <div key={data.art_name}>
        <span className="table-data-number">[{data.art_kennung}] </span>
        <span className="table-data-content">{data.art_name}</span>
      </div>,
    ];
  }

  function ass5Template(data, index) {
    return [
      <div key={data.bandbreite_name}>
        <span className="table-data-number">[{data.bandbreite_kennung}] </span>
        <span className="table-data-content">{data.bandbreite_name}</span>
      </div>,
    ];
  }

  function epCodeTemplate(data, index) {
    const showCode = data.ep_code.replace("-", " - ");
    return [
      <div key={data.ep_code}>
        <span className="table-data-content">{showCode}</span>
      </div>,
    ];
  }

  function gefmaCodeTemplate(data, index) {
    return [
      <div key={data.gefma_code}>
        <span className="table-data-content">{data.gefma_code}</span>
      </div>,
    ];
  }
  function vortextTemplate(data, index) {
    return [
      <div key={data.vortext}>
        <span className="table-data-content">{data.vortext}</span>
      </div>,
    ];
  }
  function nachtextTemplate(data, index) {
    return [
      <div key={data.nachtext}>
        <span className="table-data-content">{data.nachtext}</span>
      </div>,
    ];
  }
  function einheitTemplate(data, index) {
    return [
      <div key={data.einheit}>
        <span className="table-data-content">{data.einheit}</span>
      </div>,
    ];
  }

  function actionCopyTemplate(data, index) {
    if (props.copyOption === OPTION_COPY_EP) {
      return (
        <CopyToClipboard
          text={data.ep_code}
          onCopy={() => {
            props.notifyGlobal("EP-Code kopiert", data.ep_code, 1000);
            props.hideSettings();
          }}
        >
          <Button type="button" className="in_table_btn">
            <i className="pi pi-copy table_btn_icon" />
          </Button>
        </CopyToClipboard>
      );
    } else if (props.copyOption === OPTION_COPY_EXCEL) {
      const arr = [
        [
          "",
          data.kategorie_gruppe_kennung
            ? '"' + data.kategorie_gruppe_kennung + '"'
            : "",
          data.kategorie_gruppe_kennung
            ? '"' + data.kategorie_gruppe_kennung + '"'
            : "",
          data.kategorie_titel_kennung
            ? '"' + data.kategorie_titel_kennung + '"'
            : "",
          data.kategorie_untertitel_kennung
            ? '"' + data.kategorie_untertitel_kennung + '"'
            : "",
          data.titel_nummer ? '"' + data.titel_nummer + '"' : "",
          data.titel_nummer ? '"' + data.titel_nummer + '."' : "",
          "",
          data.kategorie_gruppe_name
            ? '"' + data.kategorie_gruppe_name + '"'
            : "",
          data.kategorie_gruppe_beschreibung
            ? '"' + data.kategorie_gruppe_beschreibung + '"'
            : "",
          data.kategorie_titel_name
            ? '"' + data.kategorie_titel_name + '"'
            : "",
          data.kategorie_titel_beschreibung
            ? '"' + data.kategorie_titel_beschreibung + '"'
            : "",
          data.kategorie_untertitel_name
            ? '"' + data.kategorie_untertitel_name + '"'
            : "",
          data.kategorie_untertitel_beschreibung
            ? '"' + data.kategorie_untertitel_beschreibung + '"'
            : "",
          data.kategorie_kurztext ? '"' + data.kategorie_kurztext + '"' : "",
          data.gefma_code ? '"' + data.gefma_code + '"' : "",
          data.kostengruppe_kennung
            ? '"' + data.kostengruppe_kennung + '"'
            : "",
          data.kostengruppe_name ? '"' + data.kostengruppe_name + '"' : "",
          data.klasse_name ? '"' + data.klasse_name + '"' : "",
          data.anlage_name ? '"' + data.anlage_name + '"' : "",
          data.art_name ? '"' + data.art_name + '"' : "",
          data.bandbreite_name ? '"' + data.bandbreite_name + '"' : "",
          data.einheit ? '"' + data.einheit + '"' : "",
          data.unit_nummer ? '"' + data.unit_nummer + '"' : "",
          data.ep_code ? '"' + data.ep_code + '"' : "",
          data.kalkulationstyp_kennung
            ? '"' + data.kalkulationstyp_kennung + '"'
            : "",
          "",
          data.vorext ? '"' + data.vorext + '"' : "",
          data.nachtext ? '"' + data.nachtext + '"' : "",
          "",
          "",
          "0",
          "0",
          "0",
        ],
      ];
      const excelData = arr.map((lines) => lines.join("\t")).join("\n");
      return (
        <CopyToClipboard
          options={{ format: "text/plain" }}
          text={excelData}
          onCopy={() => {
            props.notifyGlobal("EP kopiert", "Default-Excel-Template", 1000);
            props.hideSettings();
          }}
        >
          <Button type="button" className="in_table_btn">
            <i className="pi pi-copy table_btn_icon" />
          </Button>
        </CopyToClipboard>
      );
    }
    return;
  }

  function rowExpansionTemplate(data, index) {
    return <EpDetail data={data} />;
  }

  const formatDate = (value) => {
    if (value) {
      return new Intl.DateTimeFormat("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(value));
    } else {
      return "invalid";
    }
  };

  const injectData = () => {
    let data = null;
    if (props.epData) {
      data = props.epData;
    }
    const date = props.data_valid_timestamp
      ? "- [Stand: " + formatDate(props.data_valid_timestamp) + "]"
      : null;
    const header = data
      ? data.length + " Einträge vorhanden " + (date ? date : "")
      : null;
    return data ? (
      <DataTable
        value={data}
        rowGroupMode="subheader"
        groupField="titleNo"
        sortMode="single"
        sortField="titleNo"
        sortOrder={1}
        autoLayout
        rowGroupHeaderTemplate={headerTemplate}
        rowGroupFooterTemplate={footerTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        className="p-datatable-sm"
        columnResizeMode="expand"
        header={header}
      >
        <Column expander />
        {props.displayOptions.showKostengruppe ? (
          <Column body={ass1Template} header="Kostengruppe" />
        ) : null}
        {props.displayOptions.showKlasse ? (
          <Column body={ass2Template} header="Klasse" />
        ) : null}
        {props.displayOptions.showAnlage ? (
          <Column body={ass3Template} header="Anlage" />
        ) : null}
        {props.displayOptions.showArt ? (
          <Column body={ass4Template} header="Art" />
        ) : null}
        {props.displayOptions.showBandbreite ? (
          <Column body={ass5Template} header="Bandbreite" />
        ) : null}
        {props.displayOptions.showEpCode ? (
          <Column body={epCodeTemplate} header="EP-Code" />
        ) : null}
        {props.displayOptions.showGefmaCode ? (
          <Column body={gefmaCodeTemplate} header="Gefma Code" />
        ) : null}
        {props.displayOptions.showEinheit ? (
          <Column body={einheitTemplate} header="Einheit" />
        ) : null}
        {props.displayOptions.showVortext ? (
          <Column body={vortextTemplate} header="Vortext" />
        ) : null}
        {props.displayOptions.showNachtext ? (
          <Column body={nachtextTemplate} header="Nachtext" />
        ) : null}
        <Column body={actionCopyTemplate} style={{ width: "50px" }} />
      </DataTable>
    ) : null;
  };

  return (
    <div className="content-container card">
      <Search
        onSearch={(e) => props.search(e.in1, e.in2, e.in3, props.searchOptions)}
      />
      <div id="result-container">
        <TableLoadingOverlay />
        {injectData()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    epData: state.data.epData,
    epLoading: state.data.epLoading,
    epError: state.data.epError,
    displayOptions: state.app.displayOptions,
    searchOptions: state.app.searchOptions,
    copyOption: state.app.copyOption,
    data_valid_timestamp: state.data.epDataTimestamp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideSettings: () => dispatch(hideSettingsUI()),
    search: (searchText1, searchText2, searchText3, searchOptions) =>
      dispatch(
        searchForEp(searchText1, searchText2, searchText3, searchOptions)
      ),
    notifyGlobal: (summary, detail, life) =>
      dispatch(createGlobalNotification(summary, detail, life)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EpSearch);
