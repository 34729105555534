import {
  EP_SEARCH_REQUEST,
  EP_SEARCH_SUCCESS,
  EP_SEARCH_FAILURE,
  EP_BROWSE_REQUEST,
  EP_BROWSE_SUCCESS,
  EP_BROWSE_FAILURE,
  EP_CLEAR_SEARCH_DATA,
  EP_CLEAR_BROWSE_DATA,
} from "./epDataActionTypes";
import axios from "axios";
import { URL_TO_API } from "../../helper/constants";
import { hideSettingsUI } from "../app/appActions";

export const searchEpRequest = () => {
  return {
    type: EP_SEARCH_REQUEST,
  };
};

export const searchEpSuccess = (data, timestamp) => {
  return {
    type: EP_SEARCH_SUCCESS,
    payload: { data: data, timestamp: timestamp },
  };
};

export const searchEpFailure = (error) => {
  return {
    type: EP_SEARCH_FAILURE,
    payload: error,
  };
};

export const browseEpRequest = () => {
  return {
    type: EP_BROWSE_REQUEST,
  };
};

export const browseEpSuccess = (data, timestamp) => {
  return {
    type: EP_BROWSE_SUCCESS,
    payload: { data: data, timestamp: timestamp },
  };
};

export const browseEpFailure = (error) => {
  return {
    type: EP_BROWSE_FAILURE,
    payload: error,
  };
};

export const clearEpSearchData = () => {
  return {
    type: EP_CLEAR_SEARCH_DATA,
  };
};

export const clearEpBrowseData = () => {
  return {
    type: EP_CLEAR_BROWSE_DATA,
  };
};

//FUNCTIONS with side effects
export const searchForEp = (
  searchText1,
  searchText2,
  searchText3,
  searchParams
) => {
  return (dispatch) => {
    dispatch(hideSettingsUI());
    dispatch(searchEpRequest());
    if (searchText1 || searchText2 || searchText3) {
      axios({
        method: "post",
        baseURL: URL_TO_API,
        url: "/v1/search",
        headers: {},
        data: {
          searchString1: searchText1,
          searchString2: searchText2,
          searchString3: searchText3,
          searchOptions: {
            timestamp: searchParams.searchDate,
            caseSensitive: searchParams.searchCaseSensitive,
            kategorie_gruppe_kennung: searchParams.searchMSKategorie1Title,
            kategorie_gruppe_name: searchParams.searchMSKategorie1ShortText,
            kategorie_gruppe_beschreibung:
              searchParams.searchMSKategorie1Description,
            kategorie_titel_kennung: searchParams.searchMSKategorie2Title,
            kategorie_titel_name: searchParams.searchMSKategorie2ShortText,
            kategorie_titel_beschreibung:
              searchParams.searchMSKategorie2Description,
            kategorie_untertitel_kennung: searchParams.searchMSKategorie3Title,
            kategorie_untertitel_name: searchParams.searchMSKategorie3ShortText,
            kategorie_untertitel_beschreibung:
              searchParams.searchMSKategorie3Description,
            kostengruppe_kennung: searchParams.searchKostengruppeId,
            kostengruppe_name: searchParams.searchKostengruppeDescription,
            klasse_kennung: searchParams.searchKlasseId,
            klasse_name: searchParams.searchKlasseDescription,
            anlage_kennung: searchParams.searchAnlageId,
            anlage_name: searchParams.searchAnlageDescription,
            art_kennung: searchParams.searchArtId,
            art_name: searchParams.searchArtDescription,
            bandbreite_kennung: searchParams.searchBandbreiteId,
            bandbreite_name: searchParams.searchBandbreiteDescription,
            unit_nummer: searchParams.searchUnitNumber,
            gefma_code: searchParams.searchGefmaCode,
            einheit: searchParams.searchEinheit,
            vortext: searchParams.searchVortext,
            nachtext: searchParams.searchSchlagworte,
            schlagworte: searchParams.searchNachtext,
            // anpassbar machen
            ep_code: false,
            kalkulationstyp_kennung: false,
            intervall_kennung: false,
            stundensatz_kennung: false,
            projektfaktor_kennung: false,
            leistungsbereichfaktor_kennung: false,
          },
          resultParams: {
            result_as_tree: false,
            anlage_kennung: true,
            anlage_name: true,
            art_kennung: true,
            art_name: true,
            bandbreite_kennung: true,
            bandbreite_name: true,
            einheit: true,
            ep_code: true,
            gefma_code: true,
            intervall_kennung: true,
            kalkulationstyp_kennung: true,
            kategorie_gruppe_beschreibung: true,
            kategorie_gruppe_kennung: true,
            kategorie_gruppe_name: true,
            kategorie_kurztext: true,
            kategorie_titel_beschreibung: true,
            kategorie_titel_kennung: true,
            kategorie_titel_name: true,
            kategorie_untertitel_beschreibung: true,
            kategorie_untertitel_kennung: true,
            kategorie_untertitel_name: true,
            klasse_kennung: true,
            klasse_name: true,
            kostengruppe_kennung: true,
            kostengruppe_name: true,
            leistungsbereichfaktor_kennung: true,
            nachtext: true,
            projektfaktor_kennung: true,
            schlagworte: true,
            stundensatz_kennung: true,
            unit_nummer: true,
            vortext: true,
          },
        },
      })
        .then((response) => {
          const ep = response.data.flat;
          const validTimestamp = response.data.dataState;
          //delayed display
          setTimeout(() => {
            dispatch(searchEpSuccess(ep, validTimestamp));
          }, 800);
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(searchEpFailure(errorMsg));
        });
    } else {
      dispatch(clearEpSearchData());
    }
  };
};

export const browseAllEp = (timestamp) => {
  return (dispatch) => {
    dispatch(hideSettingsUI());
    dispatch(browseEpRequest());
    axios({
      method: "post",
      baseURL: URL_TO_API,
      url: "/v1/browse",
      headers: {},
      data: {
        resultParams: {
          result_as_tree: true,
          anlage_kennung: true,
          anlage_name: true,
          art_kennung: true,
          art_name: true,
          bandbreite_kennung: true,
          bandbreite_name: true,
          einheit: true,
          ep_code: true,
          gefma_code: true,
          intervall_kennung: true,
          kalkulationstyp_kennung: true,
          kategorie_gruppe_beschreibung: true,
          kategorie_gruppe_kennung: true,
          kategorie_gruppe_name: true,
          kategorie_kurztext: true,
          kategorie_titel_beschreibung: true,
          kategorie_titel_kennung: true,
          kategorie_titel_name: true,
          kategorie_untertitel_beschreibung: true,
          kategorie_untertitel_kennung: true,
          kategorie_untertitel_name: true,
          klasse_kennung: true,
          klasse_name: true,
          kostengruppe_kennung: true,
          kostengruppe_name: true,
          leistungsbereichfaktor_kennung: true,
          nachtext: true,
          projektfaktor_kennung: true,
          schlagworte: false,
          stundensatz_kennung: true,
          unit_nummer: true,
          vortext: true,
        },
        timestamp: timestamp,
      },
    })
      .then((response) => {
        const ep = response.data.tree;
        const validTimestamp = response.data.dataState;
        dispatch(browseEpSuccess(ep, validTimestamp));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(browseEpFailure(errorMsg));
      });
  };
};
