import {
  SHOW_SEARCH,
  SHOW_BROWSE,
  SHOW_SETTINGS,
  HIDE_SETTINGS,
  ADAPT_DISPLAY_OPTIONS,
  ADAPT_SEARCH_OPTIONS,
  ADAPT_COPY_OPTIONS,
} from "./appTypes";

export const showSearchUI = () => {
  return {
    type: SHOW_SEARCH,
  };
};

export const showBrowseUI = () => {
  return {
    type: SHOW_BROWSE,
  };
};

export const showSettingsUI = () => {
  return {
    type: SHOW_SETTINGS,
  };
};

export const hideSettingsUI = () => {
  return {
    type: HIDE_SETTINGS,
  };
};

export const adaptCopyOptions = (option) => {
  return {
    type: ADAPT_COPY_OPTIONS,
    payload: option,
  };
};

export const adaptDisplayOptions = (
  showKostengruppe,
  showKlasse,
  showAnlage,
  showArt,
  showBandbreite,
  showEpCode,
  showGefmaCode,
  showEinheit,
  showVortext,
  showNachtext
) => {
  return {
    type: ADAPT_DISPLAY_OPTIONS,
    payload: {
      showKostengruppe,
      showKlasse,
      showAnlage,
      showArt,
      showBandbreite,
      showEpCode,
      showGefmaCode,
      showEinheit,
      showVortext,
      showNachtext,
    },
  };
};

export const adaptSearchOptions = (
  searchDate,
  searchCaseSensitive,
  searchMSKategorie1Title,
  searchMSKategorie1ShortText,
  searchMSKategorie1Description,
  searchMSKategorie2Title,
  searchMSKategorie2ShortText,
  searchMSKategorie2Description,
  searchMSKategorie3Title,
  searchMSKategorie3ShortText,
  searchMSKategorie3Description,
  searchKostengruppeId,
  searchKostengruppeDescription,
  searchKlasseId,
  searchKlasseDescription,
  searchAnlageId,
  searchAnlageDescription,
  searchArtId,
  searchArtDescription,
  searchBandbreiteId,
  searchBandbreiteDescription,
  searchUnitNumber,
  searchGefmaCode,
  searchEinheit,
  searchVortext,
  searchSchlagworte,
  searchNachtext,
  searchEpCode,
  searchKalkulationstyp,
  searchIntervall,
  searchStundensatz,
  searchProjektfaktor,
  searchLeistungsbereichfaktor
) => {
  return {
    type: ADAPT_SEARCH_OPTIONS,
    payload: {
      searchDate,
      searchCaseSensitive,
      searchMSKategorie1Title,
      searchMSKategorie1ShortText,
      searchMSKategorie1Description,
      searchMSKategorie2Title,
      searchMSKategorie2ShortText,
      searchMSKategorie2Description,
      searchMSKategorie3Title,
      searchMSKategorie3ShortText,
      searchMSKategorie3Description,
      searchKostengruppeId,
      searchKostengruppeDescription,
      searchKlasseId,
      searchKlasseDescription,
      searchAnlageId,
      searchAnlageDescription,
      searchArtId,
      searchArtDescription,
      searchBandbreiteId,
      searchBandbreiteDescription,
      searchUnitNumber,
      searchGefmaCode,
      searchEinheit,
      searchVortext,
      searchSchlagworte,
      searchNachtext,
      searchEpCode,
      searchKalkulationstyp,
      searchIntervall,
      searchStundensatz,
      searchProjektfaktor,
      searchLeistungsbereichfaktor,
    },
  };
};
