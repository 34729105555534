import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './helper/ScrollToTop'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { history } from './helper/history'

ReactDOM.render(
<React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
