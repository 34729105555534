import React from "react";

export default function Legal(props) {
  return (
    <div className="content-container card">
      <h1>Impressum</h1>
      <h2>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          SERTHORO GmbH
        </a>
      </h2>
      <p>
        Herzog-Max-Str. 44 <br /> 96047 Bamberg
        <br />
        Deutschland
      </p>
      <p>
        <em>Vertreten durch:</em>
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://de.linkedin.com/in/sebastianhoelzlein"
        >
          Sebastian Hölzlein
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ch.linkedin.com/in/roger-krieg-654b77112"
        >
          Roger Krieg
        </a>
      </p>

      <h2>Kontakt</h2>
      <p>
        Tel: +49 951 994 3360 <br /> Fax: +49 951 917 68340 <br /> Email:{" "}
        <a href="mailto:info@beestate.io">info@beestate.io</a>
        <br /> USt-IdNr: DE 325 295 657 <br />
        Handelsregisterdaten: HRB 9606 ∙ Amtsgericht Bamberg
      </p>
      <p>
        <em>Verantwortlich für Inhalte gem. § 55 RStV ist:</em>
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://de.linkedin.com/in/sebastianhoelzlein"
        >
          Sebastian Hölzlein
        </a>
      </p>
      <h2>Verantwortlich für Inhalt, Urheberrecht und Markenschutz</h2>
      <p>
        Copyright 2019.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>
        . Alle Rechte vorbehalten. <br />
        Alle Texte, Bilder, Grafiken, Multimediadateien sowie das Layout
        unterliegen dem Urheberrecht und den Gesetzen zum Schutz geistigen
        Eigentums. <br />
        Eine Weiterverwertung bedarf der ausdrücklichen Zustimmung der{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH.
        </a>{" "}
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/unternehmen/"
        >
          Beestate
        </a>{" "}
        ist eine beim{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dpma.de/"
        >
          Deutschen Patent- und Markenamt (DPMA)
        </a>{" "}
        geschützte Wort- und Bildmarke.
      </p>
      <h2>Rechtshinweis</h2>
      <h3>Inhalte</h3>
      <p>
        Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt.
        Die{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und
        Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der
        Website erfolgt auf eigene Gefahr des Nutzers. Namentlich
        gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und
        nicht immer die Meinung von{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        wieder. Die Vervielfältigung von Informationen oder Daten, insbesondere
        die Verwendung von Texten, Textteilen oder Bildmaterial, bedarf der
        vorherigen Zustimmung der{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>
        . Insbesondere dürfen Nachdruck, Aufnahme in Online-Dienste, Internet
        und Vervielfältigung auf Datenträger wie CD-ROM, DVD-ROM, etc., auch
        auszugsweise, nur nach vorheriger schriftlicher Zustimmung durch die{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        erfolgen. Inhalt und Struktur der{" "}
        <a href="https://beestate.io/">Serthoro GmbH</a> Webseiten sind
        urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder
        Daten, insbesondere die Verwendung von Texten, Textteilen oder
        Bildmaterial, bedarf der vorherigen Zustimmung der{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>
        .
      </p>
      <h3>Bild- und Videomaterial</h3>
      <p>
        Bilder oder Filmmaterial (soweit zutreffend) unter Lizenz von{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.shutterstock.com/de/"
        >
          Shutterstock.com
        </a>{" "}
        und{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.pexels.com/de-de/"
        >
          Pexels.com
        </a>{" "}
        verwendet.
      </p>
      <h3>Haftung für fremde Inhalte</h3>
      <p>
        Sofern{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        direkt oder indirekt auf fremde Internetseiten verweist („Links“),
        haftet{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        nur, wenn sie von den Inhalten genaue Kenntnis hat und es ihr technisch
        möglich und zumutbar ist, die Nutzung im Falle rechtswidriger Inhalte zu
        verhindern.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>
        erklärt ausdrücklich, dass zum Zeitpunkt der Linksetzung die verlinkten
        Seiten keine illegalen Inhalte enthalten haben.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        besitzt keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung
        der verlinkten Seiten.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        distanziert sich ausdrücklich von allen inhaltlichen Änderungen, die
        nach der Linkssetzung auf den verlinkten Seiten vorgenommen werden.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        ist nicht verantwortlich für den Inhalt, die Verfügbarkeit und
        Richtigkeit der verlinkten Seiten, deren Angebote, Links oder
        Werbeanzeigen.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://beestate.io/"
        >
          Serthoro GmbH
        </a>{" "}
        haftet nicht für illegale, fehlerhafte oder unvollständige Inhalte und
        insbesondere für Schäden, die durch Nutzung oder Nichtnutzung der auf
        den verlinkten Seiten angebotenen Informationen entstehen.
      </p>
      <h3>Rechtswirksamkeit</h3>
      <p>
        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
        betrachten, von dem aus auf diese Seite verwiesen wurde. <br />
        Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden
        Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten,
        bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer
        Gültigkeit hiervon unberührt.
      </p>
    </div>
  );
}
