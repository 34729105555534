import React from "react";
import "./Footer.css";

function Footer(props) {
  return (
    <div className="bee-footer">
      <a target="_blank" href="/terms" className="bee-footer-link">
        Nutzungsbedingungen
      </a>
      <a target="_blank" href="/privacy" className="bee-footer-link">
        Datenschutz
      </a>
      <a target="_blank" href="/legal" className="bee-footer-link">
        Impressum
      </a>
    </div>
  );
}

export default Footer;
